import { Grid } from "@material-ui/core";

import runtimeEnv from "@mars/heroku-js-runtime-env";
import React from "react";

const HeaderBar = ({children}) => {
  return (
    <Grid
      position="fixed"
      style={{
        boxShadow: "none",
        backgroundColor: process.env.REACT_APP_PRIMARY_COLOR,
      }}
    >
      {children}
    </Grid>
  );
};

export default HeaderBar;
