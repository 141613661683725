import React from "react";

import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import { appStoreSet } from "../utils/app_storage";
import { useSelector } from "react-redux";

const BalanceView = (props) => {
    const { classes, corporate_settings, loans, retailer, has_pending_order } = props;
    const retailer_data = useSelector((state) => state.auth.retailer_data);
    const history = useHistory();

    const loans_balance = (loans) => {
        let sum = loans.reduce(function(prev, current) {
            return prev + +current.balance
        }, 0);
        return sum;
    };

    const total_loans_balance = loans_balance(loans);

    const currentFinanceLimit = () => {
        let limit = financeLimit() - total_loans_balance
        return limit <= 0 ? 0 : limit
    }

    const financeLimit = () => {
        let limit = retailer.finance_limit;

        if (!limit) {
          return 0;
        }
        return limit;
    };

    const toCurrency = (number) => {
        const formatter = new Intl.NumberFormat("en-us", {
          style: "currency",
          currency: "KSH",
        });

        return formatter.format(number);
    };

    const repayMultipleLoans = () => {
        history.push("/repay_loans");
    };

    const newOrder = () => {
        history.push("/ordering");
    };


    if (corporate_settings && corporate_settings.allow_multiple_loans === true) {
      appStoreSet("has_active_loan", true);

      if (total_loans_balance > 0 && has_pending_order === false) {
        return (
          <Container className={classes.page_container}>
            <Grid container justify="center" className={classes.todays_balance_card}>
              <Grid container justify="center">
                <Typography
                  className={classes.todays_balance_card_title}
                >
                  Total {retailer_data.is_la_riba === true ? ' facility ' : ' loan '} balance
                </Typography>
              </Grid>
              <Grid container justify="center">
                <Typography variant="h4" className={classes.todays_balance_total}>
                  {toCurrency(total_loans_balance)}
                </Typography>
              </Grid>
              <Grid container justify="center">
                <Typography
                  variant="h6"
                  className={classes.todays_balance_card_description}
                >
                  My current {retailer_data.is_la_riba === true ? ' facility ' : ' loan '} limit: { toCurrency(currentFinanceLimit())}
                </Typography>
              </Grid>
              <Grid container justify="center">
                <Button
                  align="center"
                  type="submit"
                  disabled={total_loans_balance <= 0 ? true : false}
                  className={classes.todays_balance_card_button}
                  onClick={repayMultipleLoans}
                >Repay {retailer_data.is_la_riba === true ? ' facility ' : ' loan '}
                </Button>
                {corporate_settings && corporate_settings.normal_ordering === true && (
                    <Button
                        variant="outlined"
                        align="center"
                        size="large"
                        fullWidth={true}
                        type="submit"
                        className={classes.word_button}
                        onClick={newOrder}
                        style={{ width: "100%" }}
                    >
                        <b>New Order</b>
                    </Button>
                )}
              </Grid>
            </Grid>
          </Container>
        )
      } else if(total_loans_balance === 0 && has_pending_order === false)  {
        return (
            <Container className={classes.page_container}>
              <Grid container justify="center">
                <Typography
                  variant="h6"
                  className={classes.top_headers}
                  gutterBottom
                >
                  Hello, your finance limit is
                </Typography>
              </Grid>
              <Grid container justify="center">
                <Typography variant="h4" gutterBottom>
                  {toCurrency(currentFinanceLimit())}
                </Typography>
              </Grid>
              <Grid container justify="center">
                <Typography
                  variant="h6"
                  className={classes.top_headers}
                  gutterBottom
                >
                  Order stock anytime. Get instant financing.
                </Typography>
              </Grid>
            </Container>
        )
      } else {
        return (
          <Container className={classes.page_container}>
            <Grid container justify="center">
              <Typography
                variant="h6"
                className={classes.top_headers}
                gutterBottom
              >
                 You have a {retailer_data.is_la_riba === true ? ' facility ' : ' loan '}pending disbursement
              </Typography>
            </Grid>
          </Container>
        )
      }
    }
  };

export default BalanceView;
