import runtimeEnv from "@mars/heroku-js-runtime-env";
import { IconButton, Toolbar } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Typography from "@material-ui/core/Typography";
import { grey, red } from "@material-ui/core/colors";
import {
    ThemeProvider,
    createMuiTheme,
    withStyles,
} from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import * as Cookies from "js-cookie";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CommonStyles from "../common/commonStyles";
import HeaderBar from "../common/header_bar";
import { session_expired } from "../store/modules/auth/actions";
import { authorization_headers } from "../utils/api_authorization";
import { appStoreGet, appStoreRemove } from "../utils/app_storage";
import { decrypt, encrypt } from "../utils/crypto_encryption";


const primary_main_color = process.env.REACT_APP_PRIMARY_COLOR || "#640032";

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_PRIMARY_COLOR,
    },
    white: {
      main: grey[50],
    },
    success: {
      main: red[500],
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro"].join(","),
    body1: {
      fontSize: "18px",
    },
  },
});

const styles = (theme) => ({
  ...CommonStyles(theme),
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  iconButton: {
    padding: 10,
    color: "white",
  },
  btn_continue_shopping: {
    margin: "10px",
    fontSize: "18px",
  },
  stickToBottom: {
    top: "auto",
    bottom: 0,
    left: "auto",
    right: "auto",
    position: "fixed",
  },
  list: {
    marginTop: "100px",
    marginBottom: "60px",
    borderRadius: "0px",
  },
  success_icon: {
    backgroundColor: "#3BB719",
    boxShadow: "none",
    color: grey[50],
    marginTop: "40px",
  },
  fail_icon: {
    backgroundColor: red[500],
    boxShadow: "none",
    color: grey[50],
    marginTop: "40px",
  },
  btn_success: {
    backgroundColor: "#3BB719",
    boxShadow: "none",
    color: grey[50],
    borderRadius: "20px",
    fontSize: "18px",
    textTransform: "unset",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
});

function PaymentStatus(props) {
  const history = useHistory();
  const { classes } = props;
  const dispatch = useDispatch();

  let shopping_cart = [];
  let created_order = null;
  let timer = null;
  let polling_count = 0;
  let mode_of_payment = null;

  if (appStoreGet("created_order") !== null)
    created_order = JSON.parse(appStoreGet("created_order"));

  if (appStoreGet("shopping_cart") !== null)
    shopping_cart = JSON.parse(appStoreGet("shopping_cart"));

  if (appStoreGet("mode_of_payment") !== null)
    mode_of_payment = appStoreGet("mode_of_payment");

  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    timer = setInterval(() => fetchStatus(), 5000);
  }, []);

  const stopInterval = () => {
    clearInterval(timer);
    setLoading(false);
    timer = null;
  };

  const fetchStatus = () => {
    let token = Cookies.get("AbsaAgiza");

    if (!token) {
      dispatch(session_expired());
      return;
    }

    if (polling_count >= 20) stopInterval();

    fetch(
      process.env.REACT_APP_SERVER_API_URL + "/payments/show?id=" + created_order.id,
      {
        headers: { ...authorization_headers(), ...{ Authorization: token } },
      }
    )
      .then((res) => res.text())
      .then((res) => {
        let data = JSON.parse(decrypt(res));
        if (data.status === "failed" || data.status === "success") {
          stopInterval();
          setStatus(data.status);
        }
      })
      .catch(() => {
        setStatus("failed");
        stopInterval();
      });

    polling_count++;
  };

  const cartQuantity = () => {
    let sum = 0;
    shopping_cart.forEach(function (item) {
      sum += item.quantity;
    });

    return sum;
  };

  if (cartQuantity() === 0) {
    history.push("/ordering");
  }

  const keepShopping = () => {
    appStoreRemove("shopping_cart");
    history.push("/");
  };

  const progressLoader = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const outletNumberType = () => {
    console.log(created_order);
    console.log(created_order.data.distributor);
    return created_order.data.distributor.mpesa_account_type;
  };

  const outletTillNumber = () => {
    return created_order.data.distributor.mpesa_account_number;
  };

  const outletName = () => {
    return created_order.data.distributor.name;
  };

  const outletPaybillAccountNumber = () => {
    return created_order.data.distributor.paybill_account_number;
  };

  const paymentAmount = () => {
    return cartTotal();
  };

  const toCurrency = (number) => {
    const formatter = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "KSH",
      minimumFractionDigits: 0,
    });

    return formatter.format(number);
  };

  const cartTotal = () => {
    let total = 0;
    created_order.data.order_data.line_items.forEach(function (item) {
      total += item.quantity * parseInt(item.price);
    });

    total += parseInt(created_order.delivery_fee_amount);

    return total;
  };

  const successPrompt = () => {
    if (mode_of_payment === "mpesa") {
      return <span>Your payment has been successfully processed</span>;
    } else {
      return (
        <span>Your payment is being processed. Please await confirmation.</span>
      );
    }
  };
  const successfulPayment = () => {
    if (status === "success") {
      return (
        <Container>
          <Grid container className={classes.root}>
            <Grid xs={12}>
              <Grid container justify="center">
                <Grid />
                <Grid>
                  <Fab
                    className={classes.success_icon}
                    align="center"
                    size="large"
                    fullWidth
                  >
                    <CheckIcon />
                  </Fab>
                </Grid>
                <Grid />
              </Grid>
            </Grid>
          </Grid>
          <Typography align="center" fullWidth>
            {successPrompt()}
          </Typography>
          <Typography align="center" style={{ marginTop: "10px" }} fullWidth>
            Order Status:
            <Button
              color="success"
              size="small"
              variant="contained"
              className={classes.btn_success}
            >
              Pending delivery
            </Button>
          </Typography>
        </Container>
      );
    }
  };

  const account_number = () => {
    let account_type = outletNumberType();
    if (account_type === "paybill") {
      return (
        <span>
          Account Number: <b>{outletPaybillAccountNumber()}</b>
          <br />
        </span>
      );
    }
  };

  const retryPayment = () => {
    const server_api_url =
      process.env.REACT_APP_SERVER_API_URL + "/payments/retry_payment";
    let token = Cookies.get("AbsaAgiza");

    if (!token) {
      dispatch(session_expired());
      return;
    }

    let order_data = JSON.stringify({ order_id: created_order.id });

    fetch(server_api_url, {
      method: "POST",
      headers: { ...authorization_headers(), ...{ Authorization: token } },
      body: encrypt(order_data),
    })
      .then((res) => {
        res
          .text()
          .then((text) => {
            window.location.reload(false);
          })
          .catch(() => setStatus("failed"));
      })
      .catch(() => setStatus("failed"));
  };

  const failedPayment = () => {
    if (status === "failed") {
      if (mode_of_payment === "mpesa") {
        return (
          <Container maxWidth="sm">
            <Grid container className={classes.root}>
              <Grid xs={12}>
                <Grid container justify="center">
                  <Grid />
                  <Grid>
                    <Fab
                      className={classes.fail_icon}
                      align="center"
                      size="large"
                      fullWidth
                    >
                      <ClearIcon />
                    </Fab>
                  </Grid>
                  <Grid />
                </Grid>
              </Grid>
            </Grid>
            <Typography align="center" fullWidth>
              <b>
                {" "}
                Sorry, we are unable to trigger instant Mpesa payment on your
                phone number. Either click ‘Retry’ or pay directly in the next 5
                minutesto: <br />
                <br />
                Mpesa{" "}
                <span style={{ textTransform: "capitalize" }}>
                  {outletNumberType()}
                </span>{" "}
                Number: <b>{outletTillNumber()}</b>
                <br />
                {account_number()}
                Amount: <b>{toCurrency(paymentAmount())}</b> <br />
                (The business name is: <b>{outletName()}</b>). <br />
                <br />
                If you cannot pay directly in the next 5 minutes, click 'Retry'
                when ready.
              </b>
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              align="center"
              className={classes.btn_checkout}
              onClick={() => retryPayment()}
              fullWidth
            >
              <b>RETRY PAYMENT</b>
            </Button>
            ;
          </Container>
        );
      } else {
        return (
          <Container maxWidth="sm">
            <Grid container className={classes.root}>
              <Grid xs={12}>
                <Grid container justify="center">
                  <Grid />
                  <Grid>
                    <Fab
                      className={classes.fail_icon}
                      align="center"
                      size="large"
                      fullWidth
                    >
                      <ClearIcon />
                    </Fab>
                  </Grid>
                  <Grid />
                </Grid>
              </Grid>
            </Grid>
            <Typography align="center" fullWidth>
              <b>Sorry, we are unable to trigger instant payment.</b>
            </Typography>
          </Container>
        );
      }
    }
  };

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={appTheme}>
        <Paper
          className={classes.container}
          style={{
            height: "100vh",
          }}
        >
            <HeaderBar>
            <Toolbar>
              <IconButton
                className={classes.iconButton}
                aria-label="directions"
                color="primary"
                onClick={() => keepShopping()}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <Typography className={classes.input} align="center" style={{color:'white'}}>
                <b>Payment confirmation</b>
              </Typography>
              
            </Toolbar>
          </HeaderBar>
          {successfulPayment()}
          {failedPayment()}
          <Container>
            <List
              className={classes.list}
              component="nav"
              style={{ marginBottom: "60px", borderRadius: "0px" }}
            >
              <ListItem>
                <ListItemText
                  primary={<Typography>Delivery address</Typography>}
                  secondary={
                    <Typography>
                      {created_order.customer_full_address}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<Typography>Order code</Typography>}
                  secondary={<Typography>{created_order.id}</Typography>}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Purchased products"
                  secondary={shopping_cart.map((product, index) => (
                    <Typography>
                      {product.quantity}x {product.name}
                    </Typography>
                  ))}
                />
              </ListItem>
            </List>
          </Container>
          <AppBar className={`${classes.stickToBottom} ${classes.contained_width}`} color="white">
            <TableContainer component={Paper}>
              <Container style={{padding: '10px'}}>
              <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  align="center"
                  className={classes.btn_checkout}
                  onClick={() => keepShopping()}
                  fullWidth
                >
                  <b>Back home</b>
                </Button>
              </Container>
            </TableContainer>
          </AppBar>
          {/* {progressLoader()} */}
        </Paper>
      </ThemeProvider>
    </Fragment>
  );
}

export default withStyles(styles)(PaymentStatus);
