import runtimeEnv from "@mars/heroku-js-runtime-env";
import AppBar from "@material-ui/core/AppBar";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { grey } from "@material-ui/core/colors";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import {
    createMuiTheme,
    ThemeProvider,
    withStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";
import * as Cookies from "js-cookie";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CommonStyles from "../common/commonStyles";
import HeaderBar from "../common/header_bar";
import { session_expired } from "../store/modules/auth/actions";
import { authorization_headers } from "../utils/api_authorization";
import { appStoreGet } from "../utils/app_storage";
import { encrypt } from "../utils/crypto_encryption";


const primary_color = process.env.REACT_APP_PRIMARY_COLOR || "#af144b";

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_PRIMARY_COLOR || "#af144b",
    },
    white: {
      main: grey[50],
    },
  },
});

const styles = (theme) => ({
  ...CommonStyles(theme),
  page_container: {
    paddingTop: "80px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: "white",
  },
  text_fields: {
    marginBottom: "30px",
  },
  iconButton: {
    padding: 10,
    color: "white",
  },
  btn_checkout: {
    margin: "2%",
  },
  stickToBottom: {
    top: "auto",
    bottom: 0,
    left: "auto",
    right: "auto",
    position: "fixed",
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  map_dimensions: {
    width: "100%",
    height: "400px",
  },
  dialog_title: {
    width: "100%",
    color: primary_color,
    fontSize: "17px",
  },
  dialog_header: {
    width: "100%",
    color: primary_color,
    fontSize: "22px",
    fontWeight: 1000,
  },
  dialog_content: {
    color: "#000000",
  },
  order_now_btn: {
    marginTop: "5%",
  },
  warning_text: {
    padding: "5%",
    fontSize: "12px",
  },
  no_decoration: {
    textDecoration: "none",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
});

function ConfirmDelivery(props) {
  const { classes } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  let shopping_cart = [];
  let phone_number = null;
  let created_order = null;

  if (appStoreGet("created_order") !== null)
    created_order = JSON.parse(appStoreGet("created_order"));

  if (appStoreGet("shopping_cart") !== null)
    shopping_cart = JSON.parse(appStoreGet("shopping_cart"));

  const [loading, setLoading] = useState(false);
  const [setStatus] = useState(null);

  if (appStoreGet("phone_number") !== null) {
    phone_number = appStoreGet("phone_number");
  }

  if (appStoreGet("created_order") !== null) {
    created_order = JSON.parse(appStoreGet("created_order"));
  }

  const cartQuantity = () => {
    let sum = 0;
    shopping_cart.forEach(function (item) {
      sum += item.quantity;
    });

    return sum;
  };

  const cartTotal = () => {
    let total = 0;
    shopping_cart.forEach(function (item) {
      total += item.quantity * parseInt(item.price);
    });

    total += parseInt(created_order.delivery_fee_amount);

    return total;
  };

  const toCurrency = (number) => {
    const formatter = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "KSH",
    });

    return formatter.format(number);
  };

  const backToShippingDetailsPage = () => {
    history.push("/shipping_details");
  };

  const orderID = () => {
    const data = {
      order_id: created_order.id,
    };

    return encrypt(JSON.stringify(data));
  };

  const makePayment = () => {
    let token = Cookies.get("AbsaAgiza");

    if (!token) {
      dispatch(session_expired());
      return;
    }

    setLoading(true);
    fetch(process.env.REACT_APP_SERVER_API_URL + "/payments/create", {
      method: "POST",
      headers: { ...authorization_headers(), ...{ Authorization: token } },
      body: orderID(),
    })
      .then((res) => {
        res
          .text()
          .then((text) => {
            if (res.ok) {
              setLoading(false);
              history.push("/payment_status");
            } else {
              setStatus("failed");
            }
          })
          .catch(() => {
            setStatus("failed");
          });
      })
      .catch(() => {
        setStatus("failed");
      });
  };

  const confirmButtonLabel = () => {
    return "MAKE PAYMENT";
  };

  const paymentMessage = () => {
    let message = "";
    message =
      "Please unlock your phone.You will receive an MPESA payment prompt of " +
      toCurrency(cartTotal()) +
      " at the phone number above. Enter your PIN to make payment.";

    return (
      <Typography align="center" style={{ marginBottom: "120px" }}>
        {message}
      </Typography>
    );
  };

  const progressLoader = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <ThemeProvider theme={appTheme}>
        <Paper
          className={classes.container}
          style={{
            height: "100vh",
          }}
        >
          <HeaderBar>
            <Toolbar>
              <IconButton
                className={classes.iconButton}
                aria-label="directions"
                color="primary"
                onClick={() => backToShippingDetailsPage()}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <Typography className={classes.input} align="center">
                <b>Confirm Delivery</b>
              </Typography>
              <IconButton
                className={classes.iconButton}
                aria-label="directions"
                color="primary"
                disableRipple
              >
                <MotorcycleIcon />
              </IconButton>
            </Toolbar>
          </HeaderBar>

          <Container className={classes.page_container}>
            {paymentMessage()}
          </Container>

          <AppBar
            className={`${classes.stickToBottom} ${classes.contained_width}`}
            color="white"
          >
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small">
                <TableBody>
                  <TableRow>
                    <TableCell align="left" className={classes.table_cell}>
                      Phone Number:
                    </TableCell>
                    <TableCell align="right" className={classes.table_cell}>
                      {phone_number}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.table_cell}>
                      Number of products:
                    </TableCell>
                    <TableCell align="right" className={classes.table_cell}>
                      {cartQuantity()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.table_cell}>
                      Delivery fee:
                    </TableCell>
                    <TableCell align="right" className={classes.table_cell}>
                      {toCurrency(created_order.delivery_fee_amount)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.table_cell}>
                      <b>TOTAL AMOUNT:</b>
                    </TableCell>
                    <TableCell align="right" className={classes.table_cell}>
                      <b>{toCurrency(cartTotal())}</b>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <Container maxWidth="sm">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  align="center"
                  className={classes.btn_checkout}
                  onClick={() => makePayment()}
                  fullWidth
                >
                  <b>{confirmButtonLabel()}</b>
                </Button>
              </Container>
            </TableContainer>
          </AppBar>

          {progressLoader()}
        </Paper>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default withStyles(styles)(ConfirmDelivery);
