import runtimeEnv from "@mars/heroku-js-runtime-env";
import { Paper } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {
    ThemeProvider,
    createMuiTheme,
    withStyles,
} from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonStyles from "../common/commonStyles";
import { update_retailer } from "../store/modules/auth/actions";
import { authorization_headers } from "../utils/api_authorization";
import { encrypt } from "../utils/crypto_encryption";



const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_PRIMARY_COLOR,
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro"].join(","),
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="outlined" {...props} />;
}

const styles = (theme) => ({
  ...CommonStyles(theme),
  alert_override: {
    "&": {
      borderLeftWidth: "4px",
      borderTop: "none",
      borderBottom: "none",
      borderRight: "none",
      boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.1)",
      width: "100%",
      backgroundColor: "white",
    },
    "& .MuiAlert-message": {
      color: "#5B4C4B",
    },
  },
  welcome_message: {
    color: "white",
    fontSize: "32px",
  },
  welcome_message_sub: {
    color: "white",
    fontSize: "20px",
  },
  logo: {
    height: "100px",
    width: "100px",
    backgroundRepeat: "no-repeat",
    marginTop: "80px",
    backgroundImage: `url(${process.env.REACT_APP_LOGO})`,
  },
  primary_button: {
    background: process.env.REACT_APP_PRIMARY_COLOR,
    color: "white",
    width: "100%",
  },
  primary_links: {
    color: process.env.REACT_APP_PRIMARY_COLOR,
    textDecoration: "none",
  },
  page_container: {
    marginTop: "20px",
    paddingTop: "40px",
    backgroundColor: "white",
    borderRadius: "8px",
  },
  fields_toolbar: {
    borderColor: "#737373",
    border: "2px solid",
    borderRadius: "5px",
    paddingLeft: "8px",
  },
  iconButton: {
    padding: 10,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: "white",
  },
  disclaimer_msg: {
    paddingTop: "10px",
    textAlign: "center",
    fontSize: "0.75rem",
  },
  disclaimer_checkbox_msg: {
    paddingTop: "5px",
    textAlign: "center",
  },
  disclaimer_checkbox: {
    marginTop: "-5px",
  },
  no_decoration: {
    textDecoration: "none",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  snackbar: {
    textAlign: "center",
    marginTop: "50px",
  },
});

function AcceptTncs(props) {
  const { classes } = props;
  const dispatch = useDispatch();
  const [state] = useState(new Map());

  const [loading, setLoading] = React.useState(false);
  const [open_snackbar, openSnackbar] = React.useState(false);
  const [snackbar_severity, setSnackbarSeverity] = React.useState("warning");
  const [snackbar_message, setSnackbarMessage] = React.useState(null);

  const retailer_id = useSelector(
    (state) => state.auth.retailer_data
  ).retailer_id;

  const handleSubmit = (event) => {
    event.preventDefault();

    let params = {
      retailer_id: retailer_id,
      security_question1_answer: state.get("security_question1_answer"),
      security_question2_answer: state.get("security_question2_answer"),
      pin: state.get("pin"),
      confirm_pin: state.get("confirm_pin"),
    };

    setLoading(true);
    fetch(process.env.REACT_APP_SERVER_API_URL + "/retailers/accept_tncs", {
      method: "POST",
      headers: authorization_headers(),
      body: encrypt(JSON.stringify(params)),
    })
      .then((res) => {
        res
          .text()
          .then((text) => {
            if (res.ok) {
              setLoading(false);
              setSnackbarMessage("Successfully accepted Terms & Conditions.");
              setSnackbarSeverity("success");
              openSnackbar(true);
              dispatch(update_retailer({}));
            } else {
              setSnackbarMessage(
                "Error submitting information. Please retry or contact support."
              );
              openSnackbar(true);
            }

            setLoading(false);
          })
          .catch(() => {
            setSnackbarMessage(
              "Error submitting information. Please retry or contact support."
            );
            openSnackbar(true);
            setLoading(false);
          });
      })
      .catch(() => {
        setSnackbarMessage(
          "Error submitting information. Please retry or contact support."
        );
        openSnackbar(true);
        setLoading(false);
      });
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    openSnackbar(false);
    setSnackbarSeverity("warning");
  };

  const snackbarLoader = () => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className={classes.snackbar}
        open={open_snackbar}
      >
        <Alert
          onClose={() => closeSnackbar()}
          severity={snackbar_severity}
          className={classes.alert_override}
        >
          {snackbar_message}
        </Alert>
      </Snackbar>
    );
  };

  const progressLoader = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={appTheme}>
        <Paper
          className={classes.container}
          style={{
            background: `url(${process.env.REACT_APP_BG_IMAGE})`,
            backgroundSize: "cover",
            height: "100vh",
          }}
        >
          <Grid position="fixed" color="white" style={{ boxShadow: "none" }}>
            <Toolbar>
              <Avatar
                src={process.env.REACT_APP_LOGO}
                variant="square"
                className={classes.square}
              />
            </Toolbar>
          </Grid>
          <Grid container justify="center">
            <Grid
              container
              style={{ width: "95%", maxWidth: "400px", marginTop: "90px" }}
            >
              <Grid container className={classes.root}>
                <Grid xs={12}>
                  <Grid container justify="center">
                    <Grid />
                    <Grid>
                      <Typography
                        variant="h5"
                        className={classes.welcome_message}
                      >
                        Welcome to {process.env.REACT_APP_ORGANISATION_NAME}
                      </Typography>
                      <Typography
                        variant="h5"
                        className={classes.welcome_message_sub}
                      >
                        Order stock anytime. Get instant financing.
                      </Typography>
                    </Grid>
                    <Grid />
                  </Grid>
                </Grid>
              </Grid>

              <Container className={classes.page_container} fullWidth>
                <form onSubmit={handleSubmit}>
                  <Typography variant="body1" align="left">
                    By consenting, you agree to have the following data shared,
                    processed and stored by Absa for purposes of providing this
                    service:
                    <ul>
                      <li>Business Name</li>
                      <li>Contact Information</li>
                      <li>Ordering History</li>
                      <li>KYC Information</li>
                    </ul>
                    By giving consent, you agree that you have have read,
                    understood and accept the Terms & Conditions{" "}
                    <a
                      href="https://wezesha-business.agiza.io/Absa%20Wezesha%20Stock%20T&Cs%20V1.pdf"
                      target="_blank"
                      rel="noreferrer"
                      className={classes.primary_links}
                    >
                      click here
                    </a>
                    .
                  </Typography>
                  <br />
                  <br />
                  <Grid container justify="center">
                    <Button
                      variant="contained"
                      align="center"
                      size="large"
                      fullWidth={true}
                      type="submit"
                      className={classes.primary_button}
                    >
                      <b>I GIVE CONSENT</b>
                    </Button>
                  </Grid>
                  <br />
                  <Grid container justify="center">
                    <Typography variant="caption">
                      {process.env.REACT_APP_FOOTER}
                    </Typography>
                  </Grid>
                  <Grid container justify="center">
                    <Typography variant="caption">
                      Powered by{" "}
                      <a
                        href="https://agiza.io/"
                        target="_blank"
                        rel="noreferrer"
                        className={classes.primary_links}
                      >
                        Agiza.io
                      </a>
                    </Typography>
                  </Grid>
                  <br />
                </form>
              </Container>

              {snackbarLoader()}
              {progressLoader()}
            </Grid>
          </Grid>
        </Paper>
      </ThemeProvider>
    </Fragment>
  );
}

export default withStyles(styles)(AcceptTncs);
