import runtimeEnv from '@mars/heroku-js-runtime-env';
import { Paper } from '@material-ui/core';
import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputBase from "@material-ui/core/InputBase";
import Snackbar from "@material-ui/core/Snackbar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MuiAlert from "@material-ui/lab/Alert";
import * as Cookies from "js-cookie";
import React, { Fragment, useState } from "react";
import * as Icon from "react-feather";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import CommonStyles from '../common/commonStyles';
import HeaderBar from '../common/header_bar';
import { session_expired } from "../store/modules/auth/actions";
import { authorization_headers } from "../utils/api_authorization";
import { encrypt } from "../utils/crypto_encryption";


const transparent_logo_path = process.env.REACT_APP_TRANSPARENT_LOGO_PATH || '/assets/images/logo_transparent.png'
const primary_main_color = process.env.REACT_APP_PRIMARY_COLOR || '#640032'
const primary_links_color = process.env.REACT_APP_PRIMARY_COLOR || '#F0325A'

const appTheme = createMuiTheme({
    palette: {
        primary: {
            main: process.env.REACT_APP_PRIMARY_COLOR,
        }
    },
    typography: {
        fontFamily: ['Source Sans Pro'].join(','),
    },
});


function Alert(props) {
    return <MuiAlert elevation={6} variant="outlined" {...props} />
}

const styles = theme => ({
    ...CommonStyles(theme),
    alert_override: {
        "&": {
            borderLeftWidth: '4px',
            borderTop: 'none',
            borderBottom: 'none',
            borderRight: 'none',
            boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.1)',
            width: '100%'
        },
        '& .MuiAlert-message': {
            color: '#5B4C4B'
        }
    },
    logo: {
        height: '100px',
        width: '100px',
        backgroundRepeat: 'no-repeat',
        marginTop: '200px',
        backgroundImage: `url(${process.env.REACT_APP_TRANSPARENT_LOGO})`,
    },
    primary_button: {
        background: process.env.REACT_APP_PRIMARY_COLOR,
        color: 'white',
        width: '100%'
    },
    primary_links: {
        color: process.env.REACT_APP_PRIMARY_COLOR,
        textDecoration: 'none',
    },
    page_container: {
        paddingTop: '80px',
        backgroundColor: 'white',
        borderRadius: '8px'
    },
    fields_toolbar: {
        borderColor: '#C9C9C9',
        border: '1px solid',
        borderRadius: '4px',
        paddingLeft: '8px',
    },
    iconButton: {
        padding: 10,
        color: 'white',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        '& .MuiInputBase-input::placeholder': {
            fontStyle: 'italic'
        }
    },
    input_label: {
        marginBottom: '2px'
    },
    no_decoration: {
        textDecoration: 'none'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    snackbar: {
        textAlign: 'center',
        marginTop: '50px'
    },
});

function ChangePin(props) {
    const {classes} = props
    const history = useHistory()
    const dispatch = useDispatch()
    const [state, setState] = useState(new Map())

    const [loading, setLoading] = React.useState(false)
    const [open_snackbar, openSnackbar] = React.useState(false)
    const [snackbar_severity, setSnackbarSeverity] = React.useState('warning')
    const [snackbar_message, setSnackbarMessage] = React.useState(null)

    const upsert = (key, value) => {
        setState((prev) => new Map(prev).set(key, value));
    }

    const sleep = (time) => {
        return new Promise((resolve) => setTimeout(resolve, time))
    }

    const handleChange = (event) => {
        const {name, value} = event.target
        upsert(name, value)
    }

    const isNumeric = (str) => {
        if (typeof str != "string") return false // only process strings!
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    const isValidated = () => {
        if (!state.get('current_pin')) {
            setSnackbarMessage("Current Pin cannot be blank.")
            openSnackbar(true)
            return false
        } else if (!state.get('pin')) {
            setSnackbarMessage("Pin cannot be blank.")
            openSnackbar(true)
            return false
        } else if (!isNumeric(state.get('pin'))) {
            setSnackbarMessage("Pin should be a 4 digit number.")
            openSnackbar(true)
            return false
        } else if (state.get('pin').length !== 4) {
            setSnackbarMessage("Pin should be a 4 digit number.")
            openSnackbar(true)
            return false
        } else if (!state.get('confirm_pin')) {
            setSnackbarMessage("Confirm pin cannot be blank.")
            openSnackbar(true)
            return false
        } else if (state.get('pin') !== state.get('confirm_pin')) {
            setSnackbarMessage("Pin do not match")
            openSnackbar(true)
            return false
        }

        return true
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        if (!isValidated()) {
            return
        }

        let token = Cookies.get("AbsaAgiza")

        if (!token) {
            dispatch(session_expired())
            return
        }

        let params = {
            current_pin: state.get('current_pin'),
            pin: state.get('pin'),
        }

        setLoading(true)
        fetch(process.env.REACT_APP_SERVER_API_URL + "/retailers/change_pin",
            {
                method: "POST",
                headers: {...authorization_headers(), ...{Authorization: token}},
                body: encrypt(JSON.stringify(params)),
            })
            .then(res => {
                res.text().then(text => {
                    if (res.ok) {
                        setLoading(false)
                        setSnackbarMessage("PIN successfully changed.")
                        setSnackbarSeverity("success")
                        openSnackbar(true)

                        sleep(5000).then(() => {
                            history.push("/")
                        })

                    } else if (res.status === 403) {
                        setSnackbarMessage("Wrong PIN.")
                        openSnackbar(true)
                    } else if (res.status === 422) {
                        setSnackbarMessage("Invalid data. Please start the PIN reset process again.")
                        openSnackbar(true)
                    } else {
                        setSnackbarMessage("Error submitting information. Please retry or contact support.")
                        openSnackbar(true)
                    }

                    setLoading(false)
                }).catch(() => {
                    setSnackbarMessage("Error submitting information. Please retry or contact support.")
                    openSnackbar(true)
                    setLoading(false)
                });
            })
            .catch(() => {
                setSnackbarMessage("Error submitting information. Please retry or contact support.")
                openSnackbar(true)
                setLoading(false)
            });
    }

    const closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        openSnackbar(false)
        setSnackbarSeverity('warning')
    }

    const snackbarLoader = () => {
        return (
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                className={classes.snackbar}
                open={open_snackbar}>
                <Alert onClose={() => closeSnackbar()} severity={snackbar_severity} className={classes.alert_override}>
                    {snackbar_message}
                </Alert>
            </Snackbar>
        )
    }

    const progressLoader = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        )
    }

    return (
        <Fragment>
            <CssBaseline/>
            <ThemeProvider theme={appTheme}>
            <Paper
          className={classes.container}
          style={{
            height: "100vh",
          }}
        >
        <HeaderBar>
                    <Toolbar>
                        <Link to='/' className={classes.no_decoration}>
                            <IconButton className={classes.iconButton} aria-label="directions" color="primary"
                                        disableRipple>
                                <ArrowBackIosIcon/>
                            </IconButton>
                        </Link>
                        <Typography className={classes.input} align="center" style={{color: 'white'}}>
                            <b>Change PIN</b>
                        </Typography>
                        <Link to='/' className={classes.no_decoration}>
                            <Avatar src={process.env.REACT_APP_TRANSPARENT_LOGO} variant="square" className={classes.square}/>
                        </Link>
                    </Toolbar>
                </HeaderBar>

                <Container className={classes.page_container} fullWidth>
                    <form onSubmit={handleSubmit}>
                        <Typography variant="body1" className={classes.input_label}>
                            Current PIN
                        </Typography>
                        <Toolbar className={classes.fields_toolbar}>
                            <InputAdornment position="start">
                                <Icon.Lock style={{color: '#a19f9f'}}/>
                            </InputAdornment>
                            <Divider orientation="vertical" flexItem/>
                            <InputBase
                                className={classes.input}
                                placeholder="Enter current PIN"
                                name="current_pin"
                                type="password"
                                value={state.get("answer_for_security_question1")}
                                onChange={handleChange}
                            />
                        </Toolbar>
                        <br/>
                        <Typography variant="body1" className={classes.input_label}>
                            New PIN
                        </Typography>
                        <Toolbar className={classes.fields_toolbar}>
                            <InputAdornment position="start">
                                <Icon.Lock style={{color: '#a19f9f'}}/>
                            </InputAdornment>
                            <Divider orientation="vertical" flexItem/>
                            <InputBase
                                className={classes.input}
                                placeholder="Enter new PIN"
                                name="pin"
                                type="password"
                                value={state.get("pin")}
                                onChange={handleChange}
                            />
                        </Toolbar>
                        <br/>
                        <Typography variant="body1" className={classes.input_label}>
                            Confirm new PIN
                        </Typography>
                        <Toolbar className={classes.fields_toolbar}>
                            <InputAdornment position="start">
                                <Icon.Lock style={{color: '#a19f9f'}}/>
                            </InputAdornment>
                            <Divider orientation="vertical" flexItem/>
                            <InputBase
                                className={classes.input}
                                placeholder="Confirm new PIN"
                                name="confirm_pin"
                                type="password"
                                value={state.get("confirm_pin")}
                                onChange={handleChange}
                            />
                        </Toolbar>
                        <br/>
                        <Grid container justify="center">
                            <Button variant="contained" align="center" size="large"
                                    fullWidth={true} color="primary"
                                    type="submit"
                                    className={classes.primary_button}><b>Submit</b>
                            </Button>
                        </Grid>
                    </form>
                </Container>

                {snackbarLoader()}
                {progressLoader()}
                </Paper>
            </ThemeProvider>
        </Fragment>
    )
}

export default withStyles(styles)(ChangePin);
