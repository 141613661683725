import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import * as Cookies from "js-cookie";
import { authorization_headers } from "../utils/api_authorization";
import { set_loan } from "../store/modules/loans/actions";
import { session_expired } from "../store/modules/auth/actions";
import { decrypt } from "../utils/crypto_encryption";



const useFetchActiveLoans = () => {
    const [loans, setLoans] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const dispatch = useDispatch();
    const history = useHistory();

    const retailer_data = useSelector((state) => state.auth.retailer_data);
    const retailer = retailer_data;
    const corporate_settings = retailer.corporate_settings;

    let token = Cookies.get("AbsaAgiza")

    if (!token) {
        dispatch(session_expired())
        history.push('/login')
    }

    const fetchLoans = ({ controller }) => {
        setLoading(true);
        fetch(process.env.REACT_APP_SERVER_API_URL + "/loans/loans", {
            headers: { ...authorization_headers(), ...{ Authorization: token } },
            signal: controller.signal // <-- pass signal to request
        })
        .then((res) => res.text())
        .then((res) => {
            // The loan created first is the first loan in the array
            let data = JSON.parse(decrypt(res));
            setLoans(data);
            if (corporate_settings && corporate_settings.allow_multiple_loans === true) {
                let immediate_due_loan =  data[0]
                dispatch(set_loan(immediate_due_loan))
            }
            setLoading(false);
        })
        .catch((error) => {
            setError(error);
        });
    }

    useEffect(() => {
        let token = Cookies.get("AbsaAgiza");

        if (!token) {
            dispatch(session_expired());
            history.push('/login')
        }

        const controller = new AbortController();

        fetchLoans({ controller }); // <-- pass controller

        return () => controller.abort(); // <-- return cleanup function
    }, []);

    return { loans, loading, fetchLoans, error };
};

export { useFetchActiveLoans };
