import React, { Fragment} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import runtimeEnv from "@mars/heroku-js-runtime-env";
import { Paper } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  createMuiTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { HistoryOutlined } from "@material-ui/icons";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import SettingsIcon from "@material-ui/icons/Settings";
import * as Cookies from "js-cookie";
import CommonStyles from "../common/commonStyles";
import HeaderBar from "../common/header_bar";
import BalanceView from "./BalanceView";
import { logout, session_expired } from "../store/modules/auth/actions";
import { appStoreRemove, appStoreSet } from "../utils/app_storage";
import { useFetchActiveLoans } from "../loans/UseFetchActiveLoans";



const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_PRIMARY_COLOR,
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro"].join(","),
  },
});

const styles = (theme) => ({
  ...CommonStyles(theme),
  todays_balance_card: {
    backgroundColor: "#651e33",
    color: "#fff",
    borderRadius: 15
  },
  todays_balance_card_title: {
    paddingTop: "15px",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "22px",
    letterSpacing: "0.066px"
  },
  todays_balance_total: {
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: "500",
    marginTop: "5px",
    marginBottom: "25px",
    letterSpacing: "0.131px"
  },
  todays_balance_card_description: {
    color: "#ffffff",
    fontSize: "16px",
    fontStyle: "normal",
    textAlign: "center",
    paddingLeft: "20px",
    paddingRight: "20px",
    fontWeight: "600",
    lineHeight: "22px",
    letterSpacing: "0.066px"
  },
  todays_balance_card_button: {
    marginTop: "30px",
    marginBottom: "20px",
    display: "flex",
    color: "#651E33",
    textAlign: "center",
    fontSize: '16px',
    fontWeight: "600",
    textTransform: "uppercase",
    fontStyle: 'normal',
    padding: "5px 10px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff",
    '&:hover': {
      background: "#f44336",
      color: "#ffffff",
    }
  },
  button_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: "0",
    paddingRight: "0",
  },
  page_container: {
    paddingTop: "100px",
  },
  iconButton: {
    padding: 10,
    color: "white",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: "white",
  },
  order_now_btn: {
    marginTop: "80px",
    width: "60%",
  },
  primary_button: {
    marginTop: "10px",
    background: process.env.REACT_APP_PRIMARY_COLOR,
    color: "white",
    // width: "60%",
  },
  primary_button_100: {
    marginTop: "10px",
    background: process.env.REACT_APP_PRIMARY_COLOR,
    color: "white",
    width: "100%",
  },
  finance_new_order: {
    color: "#ffffff",
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '22px',
    letterSpacing: '0.066px',
    borderRadius: '5px',
    background: '#651E33',
    padding: '10px',
    width: "100%",
    marginBottom: "40px",
    '&:hover': {
      background: "#f44336",
      color: "#ffffff",
    },
    '&.MuiButton-root.Mui-disabled': {
      color: "rgba(255, 255, 255, 0.5)",
      background: "rgba(0, 0, 0, 0.4)",
    }
  },
  word_button: {
    marginTop: "10px",
    width: "60%",
    color: process.env.REACT_APP_PRIMARY_COLOR,
    backgroundColor: "#64003226",
    border: "none",
    boxShadow: "none",
  },
  repay_loan_btn: {
    marginTop: "10px",
    width: "60%",
  },
  repay_loan_order_now_btn: {
    marginTop: "10px",
    width: "60%",
  },
  top_headers: {
    fontWeight: 300,
    fontSize: "large",
  },
  stickToBottom: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: "400px",
    '@media (max-height: 649px)': {
      marginTop: "40px",
    },
    '@media (min-height: 650px)': {
      bottom: 0,
      position: "absolute",
    }
  },
  button: {
    height: 95, // setting height/width is optional
    textTransform: "none",
    fontSize: "18px",
  },
  label: {
    // Aligns the content of the button vertically.
    flexDirection: "column",
  },
  icon: {
    fontSize: "32px !important",
    marginBottom: theme.spacing.unit,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  no_right_borders: {
    borderRight: "none",
  },
  floating_buttons: {
    boxShadow: "none",
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
  },
  user_menu_icon: {
    marginRight: "5px",
  },
  order_history: {
    textDecoration: "none",
    color: "inherit !important",
    textAlign: "center",
  },
  no_decoration: {
    textDecoration: "none",
    color: "inherit !important",
  },
});

function TodaysBalance(props) {
  const { classes } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const order = useSelector((state) => state.orders.order);
  const retailer_data = useSelector((state) => state.auth.retailer_data);
  const retailer = retailer_data;
  const [user_menu_opened, openUserMenu] = React.useState(null);
  const { loading, loans } = useFetchActiveLoans();
  const corporate_settings = retailer.corporate_settings

  const has_pending_order = order && order.status === "pending_confirmation";

  appStoreRemove("approved_order_id");
  appStoreRemove("selected_credit_card");

  let token = Cookies.get("AbsaAgiza")

  if (!token) {
      dispatch(session_expired())
      history.push('/login')
  }


  const userMenuClicked = (event) => {
    openUserMenu(event.currentTarget);
  };

  const closeUserMenu = () => {
    openUserMenu(null);
  };

  const logOut = () => {
    Cookies.remove("AbsaAgiza");
    dispatch(logout());
    history.push("/login");
  };

  const toCurrency = (number) => {
    const formatter = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "KSH",
    });

    return formatter.format(number);
  };

  const userMenu = () => {
    return (
      <div>
        <IconButton
          aria-controls="user-menu"
          aria-haspopup="true"
          className={classes.iconButton}
          aria-label="directions"
          onClick={userMenuClicked}
        >
          <MoreHorizIcon />
        </IconButton>

        <Menu
          id="user-menu"
          anchorEl={user_menu_opened}
          keepMounted
          open={Boolean(user_menu_opened)}
          onClose={closeUserMenu}
        >
          <Link to="/change_pin" className={classes.no_decoration}>
            <MenuItem>
              <SettingsIcon className={classes.user_menu_icon} />
              Change PIN
            </MenuItem>
          </Link>
          <Divider />
          <MenuItem onClick={logOut}>
            <ExitToAppIcon className={classes.user_menu_icon} />
            Logout
          </MenuItem>
        </Menu>
      </div>
    );
  };

  const financeLimit = () => {
    let limit = retailer.finance_limit;

    if (!limit) {
      return 0;
    }
    return limit;
  };

  const invoiceOrder = () => {
    history.push("/invoice_order");
  };

  const repayMultipleLoans = () => {
    history.push("/repay_loans");
  };


  const loan_in_arrears = (loans) => {
    const loanInArreas = loans.map(loan => loan.loan_status);

    return loanInArreas.includes("Arrears");

  };

  const any_loan_in_arrears = loan_in_arrears(loans);


  const loans_balance = (loans) => {
    let sum = loans.reduce(function(prev, current) {
      return prev + +current.balance
    }, 0);
    return sum;
  };

  const total_loans_balance = loans_balance(loans);

  const currentFinanceLimit = () => {
    let limit = financeLimit() - total_loans_balance
    return limit <= 0 ? 0 : limit
  }


  const progressLoader = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={appTheme}>
        <Paper
          className={classes.container}
          style={{
            height: "100vh",
          }}
        >
          <HeaderBar>
            <Toolbar>
              {userMenu()}
              <Typography className={classes.input} align="center">
                <b>{process.env.REACT_APP_ORGANISATION_NAME}</b>
              </Typography>
              <Link to="/" className={classes.no_decoration}>
                <Avatar
                  src={process.env.REACT_APP_TRANSPARENT_LOGO}
                  variant="square"
                  className={classes.square}
                />
              </Link>
            </Toolbar>
          </HeaderBar>

          <BalanceView
            classes={classes}
            corporate_settings={corporate_settings ? corporate_settings : {}}
            loans={loans ? loans : []}
            retailer={retailer ? retailer : {}}
            has_pending_order={has_pending_order}
          />

          {corporate_settings && corporate_settings.normal_ordering === false && (
            <Container className={`${classes.stickToBottom}`}
            >
              {/**Only create loan if no existing loan is in arrears, invoicing financing is true and finance limit is
               * greater than total loan balance and has no pending order**/}

              <Button
                align="center"
                type="submit"
                className={classes.finance_new_order}
                onClick={invoiceOrder}
                disabled={
                  (any_loan_in_arrears === false && corporate_settings &&
                  corporate_settings.invoice_financing === true && corporate_settings.allow_multiple_loans === true &&
                  financeLimit() > total_loans_balance  && has_pending_order === false) ? false : true
                }
              >Finance new Order
              </Button>

              <Link to="/loan_history" className={classes.order_history}>
                <Button
                  variant="raised"
                  classes={{ root: classes.button, label: classes.label }}
                >
                  <Badge badgeContent={loans && loans.length} color="error">
                    <HistoryOutlined className={classes.icon} />
                  </Badge>
                  <Typography
                    variant="h6"
                    className={classes.top_headers}
                    gutterBottom
                  >
                    {retailer_data.is_la_riba === true ? 'Facility history' : 'Loan history'}

                  </Typography>
                </Button>
              </Link>
            </Container>
          )}
          {progressLoader()}
        </Paper>
      </ThemeProvider>
    </Fragment>
  );
}

export default withStyles(styles)(TodaysBalance);
