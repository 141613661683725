import runtimeEnv from "@mars/heroku-js-runtime-env";
import { Paper } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Snackbar from "@material-ui/core/Snackbar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { grey } from "@material-ui/core/colors";
import {
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MuiAlert from "@material-ui/lab/Alert";
import * as Cookies from "js-cookie";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import CommonStyles from "../common/commonStyles";
import HeaderBar from "../common/header_bar";
import { session_expired } from "../store/modules/auth/actions";
import { authorization_headers } from "../utils/api_authorization";
import { appStoreSet } from "../utils/app_storage";


const transparent_logo_path =
  process.env.REACT_APP_TRANSPARENT_LOGO_PATH || "/assets/images/logo_transparent.png";
const primary_main_color = process.env.REACT_APP_PRIMARY_COLOR || "#640032";

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_PRIMARY_COLOR,
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro"].join(","),
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="outlined" {...props} />;
}

const styles = (theme) => ({
  ...CommonStyles(theme),
  alert_override: {
    "&": {
      borderLeftWidth: "4px",
      borderTop: "none",
      borderBottom: "none",
      borderRight: "none",
      boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.1)",
      width: "100%",
      backgroundColor: "white",
    },
    "& .MuiAlert-message": {
      color: "#5B4C4B",
    },
  },
  root: {
    flexGrow: 1,
  },
  iconButton: {
    padding: 10,
    color: "white",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: "white",
  },
  orders_date: {
    color: grey[700],
    float: "right",
    fontSize: "18px",
    fontTransformation: "",
  },
  info_label: {
    color: "black",
    fontSize: "18px",
  },
  primary_button: {
    background: process.env.REACT_APP_PRIMARY_COLOR,
    color: "white",
    width: "100%",
    fontSize: "18px",
  },
  bottom_buttons: {
    fontSize: "18px",
    textTransform: "unset",
  },
  delivered_btn: {
    backgroundColor: "#2F9FFF",
    color: grey[50],
    borderRadius: "20px",
    fontSize: "18px",
    textTransform: "unset",
  },
  no_decoration: {
    textDecoration: "none",
  },
  snackbar: {
    textAlign: "center",
    marginTop: "50px",
  },
});

function PendingOrderDetails(props) {
  const { classes } = props;
  const history = useHistory();

  const dispatch = useDispatch();
  const order = useSelector((state) => state.orders.order);

  const [open_dialog, setOpenDialog] = useState(false);

  const [loading, setLoading] = React.useState(false);
  const [open_snackbar, openSnackbar] = React.useState(false);
  const [snackbar_severity, setSnackbarSeverity] = React.useState("warning");
  const [snackbar_message, setSnackbarMessage] = React.useState(null);

  const toCurrency = (number) => {
    const formatter = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "KSH",
    });

    return formatter.format(number);
  };

  const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  const confirmDelivery = () => {
    if (order.status !== "picked") {
      return;
    }

    let token = Cookies.get("AbsaAgiza");

    if (!token) {
      dispatch(session_expired());
      return;
    }

    fetch(
      process.env.REACT_APP_SERVER_API_URL +
        "/orders/confirm_order?order_id=" +
        order.id,
      {
        method: "POST",
        headers: { ...authorization_headers(), ...{ Authorization: token } },
      }
    )
      .then((res) => {
        res
          .text()
          .then((text) => {
            if (res.ok) {
              setSnackbarMessage("Order successfully confirmed.");
              setSnackbarSeverity("success");
              openSnackbar(true);

              sleep(5000).then(() => {
                history.push("/pending_orders");
              });
            } else {
              setSnackbarMessage(
                "Error confirming order. Please retry or contact support."
              );
              openSnackbar(true);
              setLoading(false);
            }
            setLoading(false);
          })
          .catch(() => {
            setSnackbarMessage(
              "Error confirming order. Please retry or contact support."
            );
            openSnackbar(true);
            setLoading(false);
          });
      })
      .catch(() => {
        setSnackbarMessage(
          "Error confirming order. Please retry or contact support."
        );
        openSnackbar(true);
        setLoading(false);
      });
  };

  const reportIncident = () => {
    setOpenDialog(true);
  };

  const closeIncidentPopup = () => {
    setOpenDialog(false);
  };

  const editOrder = () => {
    if (
      order.line_items.length === 1 &&
      order.line_items[0].name === "Invoice order"
    ) {
      history.push("/invoice_order");
      appStoreSet(
        "invoice_order_amount",
        JSON.stringify(order.line_items[0].price)
      );
    } else {
      appStoreSet("shopping_cart", JSON.stringify(order.line_items));
      history.push("/ordering");
    }
  };

  const checkoutOrder = () => {
    appStoreSet("shopping_cart", JSON.stringify(order.line_items));
    appStoreSet("approved_order_id", order.id);
    history.push("/shipping_details");
  };

  const incidentPopup = () => {
    return (
      <Dialog open={open_dialog} aria-labelledby="form-dialog-title">
        <form>
          <DialogContent>
            <DialogContentText align="center">
              <Typography
                align="center"
                style={{ color: "black", fontSize: "18px" }}
              >
                Please contact customer care on <b>0722130120</b> to report the
                incident.
              </Typography>
            </DialogContentText>

            <Grid container justify="center">
              <Button
                variant="outlined"
                size="big"
                style={{ fontSize: "18px" }}
                onClick={() => closeIncidentPopup()}
              >
                CLOSE
              </Button>
            </Grid>
          </DialogContent>
        </form>
      </Dialog>
    );
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    openSnackbar(false);
    setSnackbarSeverity("warning");
  };

  const snackbarLoader = () => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className={classes.snackbar}
        open={open_snackbar}
      >
        <Alert
          onClose={() => closeSnackbar()}
          severity={snackbar_severity}
          className={classes.alert_override}
        >
          {snackbar_message}
        </Alert>
      </Snackbar>
    );
  };

  const progressLoader = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const confirmDeliveryButton = () => {
    if (order.status === "picked") {
      return (
        <div>
          <Grid container item justify="center">
            <Button
              variant="contained"
              color="primary"
              className={classes.primary_button}
              style={{ float: "right" }}
              onClick={() => confirmDelivery()}
            >
              Confirm Delivery
            </Button>
          </Grid>
          <br />
          <br />
          <Grid container item justify="center">
            <Button
              variant="outlined"
              className={classes.bottom_buttons}
              onClick={() => reportIncident()}
            >
              Report Incident
            </Button>
          </Grid>
        </div>
      );
    } else if (order.status === "flagged") {
      return (
        <Grid container item justify="center">
          <Button
            variant="contained"
            color="primary"
            className={classes.primary_button}
            style={{ float: "right" }}
            onClick={() => editOrder()}
          >
            Edit Order
          </Button>
        </Grid>
      );
    } else if (order.status === "approved") {
      return (
        <Grid container item justify="center">
          <Button
            variant="contained"
            color="primary"
            className={classes.primary_button}
            style={{ float: "right" }}
            onClick={() => checkoutOrder()}
          >
            Checkout
          </Button>
        </Grid>
      );
    } else if (order.status === "fail_payment" && order.payment_mode === 'Credit_card') {
      return (
        <Grid container item justify="center">
          <Button
            variant="contained"
            color="primary"
            className={classes.primary_button}
            style={{ float: "right" }}
            onClick={() => retryPayment()}
          >
            Retry payment
          </Button>
        </Grid>
      );
    }
  };

  const statusBadge = (order) => {
    let status = null;
    if (order.status === "paid") {
      status = "Pending Pickup";
    } else if (order.status === "pending_confirmation") {
      status = "Pending Confirmation";
    } else if (order.status === "approved") {
      status = "Approved";
    } else if (order.status === "flagged") {
      status = "Flagged";
    }else if (order.status === "fail_payment") {
      status = "Payment failed";
    } else {
      status = "Pending delivery";
    }

    if (order.status === "flagged") {
      return (
        <Button
          variant="contained"
          className={classes.delivered_btn}
          style={{ backgroundColor: "#ff9f00" }}
          disableElevation={true}
        >
          {status}
        </Button>
      );
    } else if (order.status === "fail_payment") {
      return (
        <Button
          variant="contained"
          className={classes.delivered_btn}
          style={{ backgroundColor: "#b50000" }}
          disableElevation={true}
        >
          {status}
        </Button>
      );
    } else {
      return (
        <Button
          variant="contained"
          className={classes.delivered_btn}
          disableElevation={true}
        >
          {status}
        </Button>
      );
    }
  };

  const paymentMode = (order) => {
    if (order.status === "flagged" || order.status === "approved") {
      return null;
    } else {
      return (
        <ListItem>
          <ListItemText
            primary={
              <Grid container className={classes.root}>
                <Grid xs={6} item>
                  <Typography variant="h6" className={classes.info_label}>
                    Payment mode
                  </Typography>
                </Grid>
                <Grid xs={6} item>
                  <Typography variant="h6" className={classes.orders_date}>
                  {order.payment_mode === 'Credit_card' ? 'Card' : order.payment_mode}
                  </Typography>
                </Grid>
              </Grid>
            }
          />
        </ListItem>
      );
    }
  };

  const retryPayment = () => {
    appStoreSet("credit_card_order", JSON.stringify(order));
      appStoreSet("credit_card_mode", "ordering");
      history.push("/initiate_payment");
  };

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={appTheme}>
        <Paper
          className={classes.container}
          style={{
            height: "100vh",
          }}
        >
          <HeaderBar>
            <Toolbar>
              <Link to="/pending_orders" className={classes.no_decoration}>
                <IconButton
                  className={classes.iconButton}
                  aria-label="directions"
                  color="primary"
                  disableRipple
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Link>
              <Typography
                className={classes.input}
                align="center"
                style={{ fontSize: "18px" }}
              >
                <b>Order No. {order.order_id}</b>
              </Typography>
              <Link to="/" className={classes.no_decoration}>
                <Avatar
                  src={process.env.REACT_APP_TRANSPARENT_LOGO}
                  variant="square"
                  className={classes.square}
                />
              </Link>
            </Toolbar>
          </HeaderBar>
          <br />
          <br />

          <Grid container justify="center">
            {statusBadge(order)}
          </Grid>
          <List
            component="nav"
            style={{ marginBottom: "60px", borderRadius: "0px" }}
          >
            <ListItem>
              <ListItemText
                primary={
                  <Grid container className={classes.root}>
                    <Grid xs={6} item>
                      <Typography variant="h6" className={classes.info_label}>
                        Created on
                      </Typography>
                    </Grid>
                    <Grid xs={6} item>
                      <Typography variant="h6" className={classes.orders_date}>
                        {order.created_on}
                      </Typography>
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>

            <Divider />

            {order.line_items.map((item, index) => (
              <div>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid container className={classes.root}>
                        <Grid xs={6} item>
                          <Typography
                            variant="body1"
                            className={classes.info_label}
                          >
                            {item.name} (x{item.quantity})
                          </Typography>
                        </Grid>
                        <Grid xs={6} item>
                          <Typography
                            variant="body1"
                            className={classes.orders_date}
                          >
                            {toCurrency(item.price)}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <Divider />
              </div>
            ))}

            <ListItem>
              <ListItemText
                primary={
                  <Grid container className={classes.root}>
                    <Grid xs={6} item>
                      <Typography variant="h6" className={classes.info_label}>
                        <b>Order total</b>
                      </Typography>
                    </Grid>
                    <Grid xs={6} item>
                      <Typography variant="h6" className={classes.orders_date}>
                        <b>{toCurrency(order.total_with_fees)}</b>
                      </Typography>
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>

            <Divider />
            {paymentMode(order)}
            <Divider />
          </List>

          <Container>
            {confirmDeliveryButton()}
            {incidentPopup()}
            {snackbarLoader()}
            {progressLoader()}
          </Container>
        </Paper>
      </ThemeProvider>
    </Fragment>
  );
}

export default withStyles(styles)(PendingOrderDetails);
