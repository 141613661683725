import "@fontsource/source-sans-pro";
import React, { useEffect } from "react";
import HttpsRedirect from "react-https-redirect";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AcceptTncs from "./accept_tncs/AcceptTncs";
import { Link, useHistory, useLocation } from "react-router-dom";
import TncsInitiate from "./accept_tncs/TncsInitiate";
import TncsOtp from "./accept_tncs/TncsOtp";
import ChangePin from "./change_pin/ChangePin";
import ConfirmDelivery from "./confirm_delivery/ConfirmDelivery";
import FmcgHome from "./fmcg_home/FmcgHome";
import TodaysBalance from "./fmcg_home/TodaysBalance";
import ForgotPinInitiate from "./forgot_pin/ForgotPinInitiate";
import ForgotPinOtp from "./forgot_pin/ForgotPinOtp";
import SecurityQuestions from "./forgot_pin/SecurityQuestions";
import ForgotSecurityQuestionsInitiate from "./forgot_security_questions/ForgotSecurityQuestionsInitiate";
import Invoicing from "./invoicing/Invoicing";
import ConfirmRepayment from "./loans/ConfirmRepayment";
import LoanDetails from "./loans/LoanDetails";
import LoanHistory from "./loans/LoanHistory";
import LoanStatus from "./loans/LoanStatus";
import LoanTCs from "./loans/LoanTCs";
import RepayLoan from "./loans/RepayLoan";
import RepayLoans from "./loans/RepayLoans";
import RepaymentStatus from "./loans/RepaymentStatus";
import Login from "./login/Login";
import LoginOtp from "./login/LoginOtp";
import Ordering from "./ordering/Ordering";
import OrderDetails from "./orders/OrderDetails";
import OrderHistory from "./orders/OrderHistory";
import PendingOrderDetails from "./orders/PendingOrderDetails";
import PendingOrders from "./orders/PendingOrders";
import PaymentStatus from "./payment_status/PaymentStatus";
import ConfirmPayment from "./payments/ConfirmPayment";
import CyberSourcePaymentStatus from "./payments/CyberSourcePaymentStatus";
import InitiatePayment from "./payments/InitiatePayment";
import RegisterInitiate from "./registration/RegisterInitiate";
import RegisterOtp from "./registration/RegisterOtp";
import RegisterSecurityQuestions from "./registration/RegisterSecurityQuestions";
import ShippingDetails from "./shipping_details/ShippingDetails";
import ShoppingCart from "./shopping_cart/ShoppingCart";
import SignUp from "./signup/SignUp";
import ProtectedRoute from "./utils/ProtectedRoute";
import * as Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { session_expired, login } from "./store/modules/auth/actions";
import RegisterInitiateLaRiba from "./registration/RegisterInitiateLaRiba";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function Main() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const retailer_data = useSelector((state) => state.auth.retailer_data);
  const retailer = retailer_data;
  const corporate_settings = retailer.corporate_settings

  const multiple_loans_allowed_no_normal_ordering = corporate_settings && corporate_settings.allow_multiple_loans === true && corporate_settings.normal_ordering === false

  const query = useQuery();
  const payment_status = query.get('status')

  useEffect(() => {
    if (Cookies.get('AbsaAgiza') === undefined) {
      dispatch(session_expired())

      if (location.pathname === '/register_initiate') {
          history.push('/register_initiate')
      } else if(location.pathname === '/register_initiate_la_riba') {
        history.push('/register_initiate_la_riba')
      } else if(location.pathname === '/login') {
          history.push('/login')
      } else if(location.pathname === '/register') {
          history.push('/register')
      } else if(location.pathname === '/terms_and_conditions') {
          history.push('/terms_and_conditions')
      } else {
          history.push('/login')
      }
    } else {
      dispatch(login(retailer));
      if (location.pathname === '/cybersource_payment_status/') {
        history.push('/cybersource_payment_status/?status='+payment_status)
      }
    }
  }, [Cookies.get('AbsaAgiza')]);

  return (
    <div>
      <div className="content">
        {/* ProtectedRoute are routes that require authentication */}
        <Switch>
          {/*<ProtectedRoute exact path="/" component={Home}/>*/}
          <ProtectedRoute
            path="/"
            exact
            component={multiple_loans_allowed_no_normal_ordering ? TodaysBalance  : FmcgHome }
          />
          <ProtectedRoute exact path="/fmcg_home" component={FmcgHome} />
          <ProtectedRoute
            exact
            path="/change_pin"
            component={ChangePin}
          />
          <ProtectedRoute
            exact
            path="/order_history"
            component={OrderHistory}
          />
          <ProtectedRoute
            exact
            path="/todays_balance"
            component={TodaysBalance}
          />
          <ProtectedRoute
            exact
            path="/order_details"
            component={OrderDetails}
          />
          <ProtectedRoute
            exact
            path="/pending_orders"
            component={PendingOrders}
          />
          <ProtectedRoute
            exact
            path="/pending_order_details"
            component={PendingOrderDetails}
          />
          <ProtectedRoute
            exact
            path="/loan_details"
            component={LoanDetails}
          />
          <ProtectedRoute
            exact
            path="/repay_loan"
            component={RepayLoan}
          />
          <ProtectedRoute
            exact
            path="/repay_loans"
            component={RepayLoans}
          />
          <ProtectedRoute exact path="/ordering" component={Ordering} />
          <ProtectedRoute
            exact
            path="/confirm_repayment"
            component={ConfirmRepayment}
          />
          <ProtectedRoute
            exact
            path="/repayment_status"
            component={RepaymentStatus}
          />
          <Route exact path="/login" component={Login} />
          <Route exact path="/login_otp" component={LoginOtp} />
          <Route
            exact
            path="/register_initiate"
            component={RegisterInitiate}
          />
          <Route
            exact
            path="/register_initiate_la_riba"
            component={RegisterInitiateLaRiba}
          />
          <Route exact path="/register_otp" component={RegisterOtp} />
          <Route
            exact
            path="/register_security_questions"
            component={RegisterSecurityQuestions}
          />
          <Route
            exact
            path="/forgot_pin_initiate"
            component={ForgotPinInitiate}
          />
          <Route exact path="/forgot_pin_otp" component={ForgotPinOtp} />
          <Route
            exact
            path="/forgot_pin_security_questions"
            component={SecurityQuestions}
          />
          <Route
            exact
            path="/forgot_security_questions_initiate"
            component={ForgotSecurityQuestionsInitiate}
          />
          <Route exact path="/accept_tncs" component={TncsInitiate} />
          <Route exact path="/tncs_otp" component={TncsOtp} />
          <Route exact path="/finalize_tncs" component={AcceptTncs} />
          <Route exact path="/register" component={SignUp} />
          <ProtectedRoute
            exact
            path="/shopping_cart"
            component={ShoppingCart}
          />
          <ProtectedRoute
            exact
            path="/shipping_details"
            component={ShippingDetails}
          />
          <ProtectedRoute
            exact
            path="/payment_status"
            component={PaymentStatus}
          />
          <ProtectedRoute
            exact
            path="/confirm_delivery"
            component={ConfirmDelivery}
          />
          <ProtectedRoute
            exact
            path="/invoice_order"
            component={Invoicing}
          />
          <ProtectedRoute
            exact
            path="/initiate_payment"
            component={InitiatePayment}
          />
          <ProtectedRoute
            exact
            path="/confirm_payment"
            component={ConfirmPayment}
          />
          <ProtectedRoute
            exact
            path="/cybersource_payment_status"
            component={CyberSourcePaymentStatus}
          />
          <ProtectedRoute exact path="/loan_terms" component={LoanTCs} />
          <ProtectedRoute exact path="/loan_status" component={LoanStatus} />
            <ProtectedRoute
              exact
              path="/loan_history"
              component={LoanHistory}
            />
        </Switch>
      </div>
    </div>
  );
}
