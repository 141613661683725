import runtimeEnv from "@mars/heroku-js-runtime-env";
import { Paper } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputBase from "@material-ui/core/InputBase";
import Snackbar from "@material-ui/core/Snackbar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {
    ThemeProvider,
    createMuiTheme,
    withStyles,
} from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import React, { Fragment, useState } from "react";
import * as Icon from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useHistory } from "react-router-dom";
import CommonStyles from "../common/commonStyles";
import { update_retailer } from "../store/modules/auth/actions";
import { authorization_headers } from "../utils/api_authorization";
import { decrypt, encrypt } from "../utils/crypto_encryption";


const bg_image_path = "/assets/images/bg_image.jpg";
const logo_path = process.env.REACT_APP_LOGO || "/assets/images/logo.png";
const organization_name = process.env.REACT_APP_ORGANISATION_NAME || "Wezesha Stock";
const primary_main_color = process.env.REACT_APP_PRIMARY_COLOR || "#640032";
const primary_links_color = process.env.REACT_APP_PRIMARY_COLOR || "#F0325A";

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_PRIMARY_COLOR,
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro"].join(","),
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="outlined" {...props} />;
}

const styles = (theme) => ({
  ...CommonStyles(theme),
  alert_override: {
    "&": {
      borderLeftWidth: "4px",
      borderTop: "none",
      borderBottom: "none",
      borderRight: "none",
      boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.1)",
      width: "100%",
      backgroundColor: "white",
    },
    "& .MuiAlert-message": {
      color: "#5B4C4B",
    },
  },
  welcome_message: {
    color: "white",
    fontSize: "32px",
  },
  welcome_message_sub: {
    color: "white",
    fontSize: "20px",
  },
  logo: {
    height: "100px",
    width: "100px",
    backgroundRepeat: "no-repeat",
    marginTop: "160px",
    backgroundImage: `url(${process.env.REACT_APP_LOGO})`,
  },
  page_container: {
    marginTop: "20px",
    paddingTop: "40px",
    backgroundColor: "white",
    borderRadius: "8px",
  },
  primary_button: {
    background: process.env.REACT_APP_PRIMARY_COLOR,
    color: "white",
    width: "100%",
  },
  primary_links: {
    color: process.env.REACT_APP_PRIMARY_COLOR,
    textDecoration: "none",
  },
  input_label: {
    marginBottom: "2px",
  },
  fields_toolbar: {
    borderColor: "#C9C9C9",
    border: "1px solid",
    borderRadius: "4px",
    paddingLeft: "8px",
  },
  iconButton: {
    padding: 10,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    "& .MuiInputBase-input::placeholder": {
      fontStyle: "italic",
    },
  },
  order_now_btn: {
    // width: '60%'
  },
  disclaimer_msg: {
    paddingTop: "10px",
    textAlign: "center",
    fontSize: "0.75rem",
  },
  disclaimer_checkbox_msg: {
    paddingTop: "5px",
    textAlign: "center",
  },
  disclaimer_checkbox: {
    marginTop: "-5px",
  },
  no_decoration: {
    textDecoration: "none",
    color: "inherit !important",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  snackbar: {
    textAlign: "center",
    marginTop: "50px",
  },
});

function ForgotPinInitiate(props) {
  const { classes } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const signed = useSelector((state) => state.auth.signed);

  const [state, setState] = useState(new Map());
  const [loading, setLoading] = React.useState(false);
  const [open_snackbar, openSnackbar] = React.useState(false);
  const [snackbar_severity, setSnackbarSeverity] = React.useState("warning");
  const [snackbar_message, setSnackbarMessage] = React.useState(null);

  if (signed) {
    return <Redirect to="/" />;
  }

  const upsert = (key, value) => {
    setState((prev) => new Map(prev).set(key, value));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    upsert(name, value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let params = {
      keid: state.get("keid"),
      phone_number: state.get("phone_number"),
    };

    setLoading(true);
    fetch(
      process.env.REACT_APP_SERVER_API_URL + "/retailers/register/initiate_pin_reset",
      {
        method: "POST",
        headers: authorization_headers(),
        body: encrypt(JSON.stringify(params)),
      }
    )
      .then((res) => {
        res
          .text()
          .then((text) => {
            if (res.ok) {
              let data = JSON.parse(decrypt(text));
              dispatch(update_retailer(data));
              history.push("/forgot_pin_otp");
            } else if (res.status === 422) {
              openSnackbar(true);
              setSnackbarMessage("The details entered are not valid.");
            } else {
              alert("Invalid phone or PIN");
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
            openSnackbar(true);
            setSnackbarMessage("Error submitting information");
          });
      })
      .catch(() => {
        setLoading(false);
        openSnackbar(true);
        setSnackbarMessage("Error submitting information");
      });
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    openSnackbar(false);
    setSnackbarSeverity("warning");
  };

  const snackbarLoader = () => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className={classes.snackbar}
        open={open_snackbar}
      >
        <Alert
          onClose={() => closeSnackbar()}
          severity={snackbar_severity}
          className={classes.alert_override}
        >
          {snackbar_message}
        </Alert>
      </Snackbar>
    );
  };

  const progressLoader = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const footer_message = () => {
    if (organization_name == "Wezesha Stock") {
      return "Absa Bank Kenya PLC is regulated by the Central Bank of Kenya.";
    } else {
      return "";
    }
  };

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={appTheme}>
        <Paper
          className={classes.container}
          style={{
            background: `url(${process.env.REACT_APP_BG_IMAGE})`,
            backgroundSize: "cover",
            height: "100vh",
          }}
        >
          <Grid
            position="fixed"
            style={{ boxShadow: "none", backgroundColor: "white" }}
          >
            <Toolbar>
              <Avatar
                src={process.env.REACT_APP_LOGO}
                variant="square"
                className={classes.square}
              />
            </Toolbar>
          </Grid>
          <Grid container justify="center">
            <Grid
              container
              style={{ width: "95%", maxWidth: "400px", marginTop: "90px" }}
            >
              <Grid container className={classes.root}>
                <Grid xs={12}>
                  <Grid container justify="center">
                    <Grid />
                    <Grid>
                      <Typography
                        variant="h5"
                        className={classes.welcome_message}
                      >
                        Welcome to {process.env.REACT_APP_ORGANISATION_NAME}
                      </Typography>
                      <Typography
                        variant="h5"
                        className={classes.welcome_message_sub}
                      >
                        Order stock anytime. Get instant financing.
                      </Typography>
                    </Grid>
                    <Grid />
                  </Grid>
                </Grid>
              </Grid>

              <Container className={classes.page_container} fullWidth>
                <form onSubmit={handleSubmit}>
                  <Typography variant="body1" className={classes.input_label}>
                    Retailer ID
                  </Typography>
                  <Toolbar className={classes.fields_toolbar}>
                    <InputAdornment position="start">
                      <Icon.Hash style={{ color: "#a19f9f" }} />
                    </InputAdornment>
                    <Divider orientation="vertical" flexItem />
                    <InputBase
                      className={classes.input}
                      placeholder="Enter retailer ID"
                      name="keid"
                      value={state.get("keid")}
                      onChange={handleChange}
                    />
                  </Toolbar>
                  <br />
                  <Typography variant="body1" className={classes.input_label}>
                    Phone number
                  </Typography>
                  <Toolbar className={classes.fields_toolbar}>
                    <InputAdornment position="start">
                      <Icon.Phone style={{ color: "#a19f9f" }} />
                    </InputAdornment>
                    <Divider orientation="vertical" flexItem />
                    <InputBase
                      className={classes.input}
                      placeholder="Enter phone number"
                      name="phone_number"
                      value={state.get("phone_number")}
                      onChange={handleChange}
                    />
                  </Toolbar>

                  <br />

                  <Grid container justify="center">
                    <Button
                      variant="contained"
                      align="center"
                      size="large"
                      fullWidth={true}
                      type="submit"
                      className={classes.primary_button}
                    >
                      <b>Submit</b>
                    </Button>
                  </Grid>
                </form>
                <br />
                <Grid container justify="center" style={{ marginTop: "7px" }}>
                  <Typography variant="body1">
                    <Link to="/login" className={classes.primary_links}>
                      Login
                    </Link>
                  </Typography>
                </Grid>
                <br />
                <Grid container justify="center">
                  <Typography variant="body1">
                    <Link
                      to="/forgot_security_questions_initiate"
                      className={classes.primary_links}
                    >
                      Forgot Security Questions
                    </Link>
                  </Typography>
                </Grid>
                <br />
                <Grid container justify="center">
                  <Typography variant="caption">{footer_message()}</Typography>
                </Grid>
                <Grid container justify="center">
                  <Typography variant="caption">
                    Powered by{" "}
                    <a
                      href="https://agiza.io/"
                      target="_blank"
                      rel="noreferrer"
                      className={classes.primary_links}
                    >
                      Agiza.io
                    </a>
                  </Typography>
                </Grid>
                <br />
              </Container>

              {snackbarLoader()}
              {progressLoader()}
            </Grid>
          </Grid>
        </Paper>
      </ThemeProvider>
    </Fragment>
  );
}

export default withStyles(styles)(ForgotPinInitiate);
