import runtimeEnv from "@mars/heroku-js-runtime-env";


const CommonStyles = (theme) => ({
  container: {
    padding: "0",
    borderRadius: "8px",
    margin: "0 auto",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      padding: "0",
      maxWidth: "400px",
    },
  },
  contained_width: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      padding: "0",
      maxWidth: "400px",
    },
  },
  outlined_button: {
    marginTop: "10px",
    color: process.env.REACT_APP_PRIMARY_COLOR,
    borderColor: process.env.REACT_APP_PRIMARY_COLOR,
  },
});

export default CommonStyles;
