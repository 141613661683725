import runtimeEnv from "@mars/heroku-js-runtime-env";
import { Paper } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Slider from "@material-ui/core/Slider";
import Snackbar from "@material-ui/core/Snackbar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { grey, red } from "@material-ui/core/colors";
import {
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MuiAlert from "@material-ui/lab/Alert";
import * as Cookies from "js-cookie";
import Moment from "moment";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import CommonStyles from "../common/commonStyles";
import HeaderBar from "../common/header_bar";
import { session_expired } from "../store/modules/auth/actions";
import { authorization_headers } from "../utils/api_authorization";
import { appStoreGet, appStoreRemove, appStoreSet } from "../utils/app_storage";
import { decrypt, encrypt } from "../utils/crypto_encryption";


const transparent_logo_path =
  process.env.REACT_APP_TRANSPARENT_LOGO_PATH || "/assets/images/logo_transparent.png";
const primary_main_color = process.env.REACT_APP_PRIMARY_COLOR || "#640032";

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_PRIMARY_COLOR,
    },
    white: {
      main: grey[50],
    },
    secondary: { main: primary_main_color },
  },
  typography: {
    fontFamily: ["Source Sans Pro"].join(","),
    body1: {
      fontSize: "18px",
    },
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="outlined" {...props} />;
}
const styles = (theme) => ({
  ...CommonStyles(theme),
  alert_override: {
    "&": {
      borderLeftWidth: "4px",
      borderTop: "none",
      borderBottom: "none",
      borderRight: "none",
      boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.1)",
      width: "100%",
      backgroundColor: "white",
    },
    "& .MuiAlert-message": {
      color: "#5B4C4B",
    },
  },
  page_container: {
    paddingTop: "80px",
  },
  input: {
    marginLeft: theme.spacing(1),
    border: "none",
    flex: 1,
    "& .MuiInputBase-input::placeholder": {
      fontStyle: "italic",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
    },
    color: "white",
  },
  text_fields: {
    marginBottom: "30px",
  },
  fields_toolbar: {
    borderColor: "#C9C9C9",
    border: "1px solid",
    borderRadius: "4px",
    paddingLeft: "8px",
  },
  iconButton: {
    padding: 10,
    color: "white",
  },
  btn_checkout: {
    margin: "2%",
    backgroundColor: primary_main_color,
    color: "white",
    fontSize: "18px",
  },
  stickToBottom: {
    top: "auto",
    bottom: 0,
    left: "auto",
    right: "auto",
    position: "fixed",
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  map_dimensions: {
    width: "100%",
    height: "400px",
  },
  dialog_title: {
    width: "100%",
    color: primary_main_color,
    fontSize: "17px",
  },
  dialog_header: {
    width: "100%",
    color: primary_main_color,
    fontSize: "22px",
    fontWeight: 1000,
  },
  dialog_content: {
    color: "#000000",
  },
  order_now_btn: {
    marginTop: "5%",
  },
  warning_text: {
    padding: "5%",
    fontSize: "12px",
  },
  no_decoration: {
    textDecoration: "none",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  snackbar: {
    textAlign: "center",
    marginTop: "50px",
  },
});

const FinanceLimitSlider = withStyles({
  root: {
    color: red[900],
  },
})(Slider);

function ShippingDetails(props) {
  const history = useHistory();
  const { classes } = props;
  const dispatch = useDispatch();

  let cached_shopping_cart = [];
  let cached_retailer_data = {};

  if (appStoreGet("shopping_cart") !== null)
    cached_shopping_cart = JSON.parse(appStoreGet("shopping_cart"));

  if (appStoreGet("retailer_data") !== null)
    cached_retailer_data = JSON.parse(appStoreGet("retailer_data"));

  appStoreRemove("mode_of_payment");

  const [shopping_cart] = useState(cached_shopping_cart);
  const [mode_of_payment, setModeOfPayment] = useState("mpesa");
  const [loan_details, setLoanDetails] = useState(null);
  const [retailer_data] = useState(cached_retailer_data);
  const [open_dialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open_snackbar, openSnackbar] = React.useState(false);
  const [snackbar_severity, setSnackbarSeverity] = React.useState("warning");
  const [snackbar_message, setSnackbarMessage] = React.useState(null);
  const retailer = retailer_data;
  const corporate_settings = retailer.corporate_settings

  let mode_of_payments;

  const cartTotal = () => {
    let total = 0;
    shopping_cart.forEach(function (item) {
      total += item.quantity * parseInt(item.price);
    });

    return total;
  };

  if (corporate_settings && corporate_settings.credit_card === true) {
    if (appStoreGet("has_active_loan") === "true" || cartTotal() < 1000) {
      mode_of_payments = [
        {
          value: "mpesa",
          label: "Mpesa",
        },
        {
          value: "credit_card",
          label: "Card",
        },
      ];
    } else {
      mode_of_payments = [
        {
          value: "mpesa",
          label: "Mpesa",
        },
        {
          value: "credit_card",
          label: "Card",
        },
        {
          value: "finance",
          label: "Finance",
        },
      ];
    }
  } else {
    if (appStoreGet("has_active_loan") === "true" || cartTotal() < 1000) {
      mode_of_payments = [
        {
          value: "mpesa",
          label: "Mpesa",
        }
      ];

      if (corporate_settings && corporate_settings.allow_multiple_loans === true) {
        mode_of_payments.push(
          {
            value: "finance",
            label: "Finance",
          }
        );
      }

    } else {
      mode_of_payments = [
        {
          value: "mpesa",
          label: "Mpesa",
        },
        {
          value: "finance",
          label: "Finance",
        },
      ];
    }
  }

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    openSnackbar(false);
    setSnackbarSeverity("warning");
  };

  const snackbarLoader = () => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className={classes.snackbar}
        open={open_snackbar}
      >
        <Alert
          onClose={() => closeSnackbar()}
          severity={snackbar_severity}
          className={classes.alert_override}
        >
          {snackbar_message}
        </Alert>
      </Snackbar>
    );
  };

  const fetchLoanDetails = () => {
    let token = Cookies.get("AbsaAgiza");

    if (!token) {
      dispatch(session_expired());
      return;
    }

    setLoading(true);
    fetch(process.env.REACT_APP_SERVER_API_URL + "/loans/loan_details", {
      method: "POST",
      headers: { ...authorization_headers(), ...{ Authorization: token } },
      body: encrypt(JSON.stringify({ loan_amount: cartTotal() })),
    })
      .then((res) => res.text())
      .then((res) => {
        let data = JSON.parse(decrypt(res));
        setLoading(false);
        setLoanDetails(data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const changeModeOfPayment = (event) => {
    let value = event.target.value;
    setModeOfPayment(value);
    appStoreSet("mode_of_payment", value);

    if (value === "finance") {
      fetchLoanDetails();
      setOpenDialog(true);
    } else {
      setOpenDialog(false);
    }
  };

  const acceptTnCs = () => {
    setOpenDialog(false);
    makePayment();
  };

  const goConfirmDeliveryPage = () => {
    if (mode_of_payment === "credit_card") {
      appStoreSet("credit_card_mode", "ordering");
      history.push("/initiate_payment");
    } else {
      makePayment();
    }
  };

  const cancelFinance = () => {
    setOpenDialog(false);
    setModeOfPayment("mpesa");
  };

  const cartQuantity = () => {
    let sum = 0;
    shopping_cart.forEach(function (item) {
      sum += item.quantity;
    });

    return sum;
  };

  const toCurrency = (number) => {
    const formatter = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "KSH",
    });

    return formatter.format(number);
  };

  const sliderPercentage = () => {
    let limit = parseInt(retailer_data.finance_limit);
    let total = cartTotal();

    return (total * 100) / limit;
  };

  const loanLimit = (classes) => {
    if (appStoreGet("has_active_loan") === "false") {
      return (
        <div>
          <Grid container justify="center">
            <Typography className={classes.top_headers} gutterBottom>
              Your finance limit is
            </Typography>
          </Grid>
          <Grid container justify="center">
            <Typography variant="h4" gutterBottom>
              {toCurrency(retailer_data.finance_limit)}
            </Typography>
          </Grid>
          <br />
        </div>
      );
    }
  };

  if (cartQuantity() === 0) {
    history.push("/ordering");
  }

  const makePayment = () => {
    let token = Cookies.get("AbsaAgiza");

    if (!token) {
      dispatch(session_expired());
      return;
    }

    if (mode_of_payment === "finance") {
      if (retailer_data.finance_limit < cartTotal()) {
        alert("The total exceeds your limit");
        return;
      }
    }

    let order_data = JSON.stringify({
      order_id: appStoreGet("approved_order_id"),
      mode_of_payment: mode_of_payment,
    });

    setLoading(true);
    fetch(process.env.REACT_APP_SERVER_API_URL + "/orders/checkout", {
      method: "POST",
      headers: { ...authorization_headers(), ...{ Authorization: token } },
      body: encrypt(order_data),
    })
      .then((res) => {
        res
          .text()
          .then((text) => {
            if (res.ok) {
              let data = JSON.parse(decrypt(text));
              setLoading(false);
              appStoreSet("created_order", JSON.stringify(data));
              appStoreSet("mode_of_payment", mode_of_payment);
              appStoreRemove("approved_order_id");
              if (mode_of_payment === "finance") {
                history.push("/payment_status");
              } else {
                history.push("/confirm_delivery");
              }
            } else {
              setSnackbarMessage(
                "Error confirming order. Please retry or contact support."
              );
              openSnackbar(true);
            }
            setLoading(false);
          })
          .catch(() => {
            setSnackbarMessage(
              "Error confirming order. Please retry or contact support."
            );
            openSnackbar(true);
            setLoading(false);
          });
      })
      .catch(() => {
        setSnackbarMessage(
          "Error confirming order. Please retry or contact support."
        );
        openSnackbar(true);
        setLoading(false);
      });
  };

  const progressLoader = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const financeDialog = () => {
    if (loan_details) {
      return (
        <Dialog open={open_dialog}  maxWidth={'xs'} aria-labelledby="form-dialog-title">
          <form>
            <DialogTitle align="center">
              <Typography
                align="center"
                className={classes.dialog_header}
                gutterBottom
              >
                Order now, pay later!
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Typography align="center" gutterBottom>
                {retailer_data.is_la_riba === true ? 'Your facility limit is' : 'Your loan limit is'}
                <Typography variant="h5">
                  {toCurrency(retailer_data.finance_limit)}
                </Typography>
              </Typography>

              <FinanceLimitSlider
                defaultValue={sliderPercentage()}
                aria-labelledby="discrete-slider-restrict"
                step={null}
                className={classes.dialog_title}
                marks={[{ value: sliderPercentage() }]}
              />

              { retailer_data.is_la_riba === true ? (
                <DialogContentText
                  align="center"
                  className={classes.dialog_content}
                >
                  <br />
                  This Order Finance Facility of <b>{toCurrency(cartTotal())}</b> will
                  attract a daily profit rate of{" "}
                  <b>
                    {loan_details.rate}% (
                    {toCurrency(loan_details.interest_amount)})
                  </b>{" "}
                  and a fee of <b>{toCurrency(loan_details.total_fees)}</b> and
                  must be repaid in <b>7 days</b>. Your total balance of{" "}
                  <b>{toCurrency(loan_details.total_amount)}</b> will be Due on{" "}
                  <b>{Moment().add(7, "d").format("DD/MM/YYYY")}</b>. Repay on
                  time to gain a higher facility limit & grow your business.
                </DialogContentText>
              ) : (
                <DialogContentText
                    align="center"
                    className={classes.dialog_content}
                  >
                    <br />
                    This Order Finance Loan of <b>{toCurrency(cartTotal())}</b> will
                    attract a daily interest of{" "}
                    <b>
                      {loan_details.rate}% (
                      {toCurrency(loan_details.interest_amount)})
                    </b>{" "}
                    and a fee of <b>{toCurrency(loan_details.total_fees)}</b> and
                    must be repaid in <b>7 days</b>. Your total balance of{" "}
                    <b>{toCurrency(loan_details.total_amount)}</b> will be Due on{" "}
                    <b>{Moment().add(7, "d").format("DD/MM/YYYY")}</b>. Repay on
                    time to gain a higher loan limit & grow your business.
                </DialogContentText>
              )}

              <Grid container justify="center">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.btn_checkout}
                  onClick={() => acceptTnCs()}
                  fullWidth
                >
                  FINANCE THIS ORDER
                </Button>
                <Button>
                  <Typography onClick={() => cancelFinance()}>
                    CANCEL
                  </Typography>
                </Button>
              </Grid>
            </DialogContent>
          </form>
        </Dialog>
      );
    }
  };

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={appTheme}>
        <Paper
          className={classes.container}
          style={{
            height: "100vh",
          }}
        >
          <HeaderBar>
            <Toolbar>
              <Link to="/shopping_cart" className={classes.no_decoration}>
                <IconButton
                  className={classes.iconButton}
                  aria-label="directions"
                  color="primary"
                  disableRipple
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Link>
              <Typography className={classes.input} align="center">
                <b>Mode of Payment</b>
              </Typography>
              <Link to="/" className={classes.no_decoration}>
                <Avatar
                  src={process.env.REACT_APP_TRANSPARENT_LOGO}
                  variant="square"
                  className={classes.square}
                />
              </Link>
            </Toolbar>
          </HeaderBar>

          <Container className={classes.page_container}>
            {loanLimit(classes)}
            <FormControl component="fieldset">
              <FormLabel component="legend">Mode of payment</FormLabel>
              <RadioGroup
                colorPrimary="primary"
                value={mode_of_payment}
                onChange={changeModeOfPayment}
              >
                {mode_of_payments.map((option) => (
                  <FormControlLabel
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>

            {financeDialog()}
          </Container>

          <AppBar className={`${classes.stickToBottom} ${classes.contained_width}`} color="white">
            <Button
              variant="contained"
              size="large"
              className={classes.btn_checkout}
              disabled={mode_of_payment === "finance"}
              onClick={() => goConfirmDeliveryPage()}
            >
              CONFIRM
            </Button>
          </AppBar>

          {snackbarLoader()}
          {progressLoader()}
        </Paper>
      </ThemeProvider>
    </Fragment>
  );
}

export default withStyles(styles)(ShippingDetails);
