import runtimeEnv from "@mars/heroku-js-runtime-env";
import { Paper } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { grey } from "@material-ui/core/colors";
import {
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SearchIcon from "@material-ui/icons/Search";
import * as Cookies from "js-cookie";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import CommonStyles from "../common/commonStyles";
import HeaderBar from "../common/header_bar";
import { session_expired } from "../store/modules/auth/actions";
import { set_order } from "../store/modules/orders/actions";
import { authorization_headers } from "../utils/api_authorization";
import { decrypt } from "../utils/crypto_encryption";


const transparent_logo_path =
  process.env.REACT_APP_TRANSPARENT_LOGO_PATH || "/assets/images/logo_transparent.png";
const primary_main_color = process.env.REACT_APP_PRIMARY_COLOR || "#640032";

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_PRIMARY_COLOR,
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro"].join(","),
  },
});

const styles = (theme) => ({
  ...CommonStyles(theme),
  root: {
    flexGrow: 1,
  },
  toolbar: {
    border: "1px solid",
    borderColor: grey[300],
  },
  iconButton: {
    padding: 10,
    color: "white",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: "white",
  },
  search_input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  orders_date: {
    color: grey[700],
    float: "right",
  },
  orders_items: {
    borderBottom: "1px solid",
    borderBottomColor: grey[400],
  },
  remove_link_decoration: {
    color: "inherit",
    textDecoration: "inherit",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  no_decoration: {
    textDecoration: "none",
  },
});

function OrderHistory(props) {
  const { classes } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [search_results, setSearchResults] = useState([]);
  const [search_value, setSearchValue] = useState("");

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = () => {
    setLoading(true);

    let token = Cookies.get("AbsaAgiza");

    if (!token) {
      dispatch(session_expired());
      return;
    }

    fetch(process.env.REACT_APP_SERVER_API_URL + "/orders/delivered", {
      headers: { ...authorization_headers(), ...{ Authorization: token } },
    })
      .then((res) => res.text())
      .then((res) => {
        let data = JSON.parse(decrypt(res));
        setOrders(data);
        setSearchResults(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const arraySearch = (array, keyword) => {
    const searchTerm = keyword.toLowerCase();
    return array.filter((value) => {
      return (
        value.order_id?.toLowerCase().match(new RegExp(searchTerm, "g")) ||
        value.created_on?.toLowerCase().match(new RegExp(searchTerm, "g")) ||
        value.payment_mode?.toLowerCase().match(new RegExp(searchTerm, "g"))
      );
    });
  };

  const handleOnChange = async (e) => {
    let value = e.target.value;
    setSearchValue(value);
    if (value.length > 2) {
      let results = await arraySearch(orders, value);
      setSearchResults(results);
    } else {
      setSearchResults(orders);
    }
  };

  const cancelSearch = () => {
    setSearchResults(orders);
    setSearchValue("");
  };

  const selectOrder = (order) => {
    dispatch(set_order(order));
    history.push("/order_details");
  };

  const progressLoader = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const noRecords = () => {
    if (orders.length <= 0 && loading === false) {
      return (
        <>
          <Container style={{ marginTop: "60px", borderRadius: "0px" }}>
            <Typography align="center" variant="subtitle1">
              No orders.
            </Typography>

            <br />
            <br />
            <br />
            <Link to="/" className={classes.no_decoration}>
              <Button
                variant="outlined"
                className={classes.bottom_buttons}
                color="primary"
                fullWidth
              >
                Back Home
              </Button>
            </Link>
          </Container>
        </>
      );
    }
  };

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={appTheme}>
        <Paper
          className={classes.container}
          style={{
            height: "100vh",
          }}
        >
          <HeaderBar>
            <Toolbar>
              <Link to="/" className={classes.no_decoration}>
                <IconButton
                  className={classes.iconButton}
                  aria-label="directions"
                  color="primary"
                  disableRipple
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Link>
              <Typography className={classes.input} align="center">
                <b>Order History</b>
              </Typography>
              <Link to="/" className={classes.no_decoration}>
                <Avatar
                  src={process.env.REACT_APP_TRANSPARENT_LOGO}
                  variant="square"
                  className={classes.square}
                />
              </Link>
            </Toolbar>
          </HeaderBar>
          <AppBar position="sticky" color="white" style={{ boxShadow: "none" }}>
            <Toolbar className={classes.toolbar}>
              <IconButton style={{ padding: 10 }} aria-label="directions">
                <SearchIcon />
              </IconButton>
              <InputBase
                value={search_value}
                onChange={handleOnChange}
                className={classes.search_input}
                placeholder="Search orders"
              />
              <Button
                style={{ color: grey[600] }}
                aria-label="directions"
                onClick={cancelSearch}
              >
                Cancel
              </Button>
            </Toolbar>
          </AppBar>

          {noRecords()}

          <List
            component="nav"
            style={{ marginBottom: "60px", borderRadius: "0px" }}
          >
            {search_results.map((order, index) => (
              <ListItem
                key={index}
                className={classes.orders_items}
                onClick={() => selectOrder(order)}
                button
              >
                <ListItemText
                  primary={
                    <Grid container className={classes.root}>
                      <Grid xs={6} item>
                        <Typography variant="h6">{order.order_id}</Typography>
                      </Grid>
                      <Grid xs={6} item>
                        <Typography
                          variant="h6"
                          className={classes.orders_date}
                        >
                          {order.created_on}
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                />
              </ListItem>
            ))}
          </List>
          {progressLoader()}
        </Paper>
      </ThemeProvider>
    </Fragment>
  );
}

export default withStyles(styles)(OrderHistory);
