import runtimeEnv from "@mars/heroku-js-runtime-env";
import { Button, Paper, Slider } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { amber, grey, red } from "@material-ui/core/colors";
import {
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MuiAlert from "@material-ui/lab/Alert";
import * as Cookies from "js-cookie";
import Moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import CommonStyles from "../common/commonStyles";
import HeaderBar from "../common/header_bar";
import { session_expired } from "../store/modules/auth/actions";
import { authorization_headers } from "../utils/api_authorization";
import { appStoreGet, appStoreSet } from "../utils/app_storage";
import { decrypt, encrypt } from "../utils/crypto_encryption";


const transparent_logo_path =
  process.env.REACT_APP_TRANSPARENT_LOGO_PATH || "/assets/images/logo_transparent.png";
const primary_main_color = process.env.REACT_APP_PRIMARY_COLOR || "#640032";

const sleep = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

const FinanceLimitSlider = withStyles({
  root: {
    color: red[900],
  },
})(Slider);

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_PRIMARY_COLOR,
    },
    secondary: {
      main: "#882456",
    },
    white: {
      main: grey[50],
      body1: {
        fontSize: "18px",
      },
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro"].join(","),
    body1: {
      fontSize: "18px",
    },
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="outlined" {...props} />;
}

const styles = (theme) => ({
  ...CommonStyles(theme),
  fields_toolbar: {
    borderColor: "#737373",
    border: "2px solid",
    borderRadius: "5px",
    paddingLeft: "8px",
  },
  upload_container: {
    marginTop: "10px",
  },
  upload_fields_toolbar: {
    borderColor: "#737373",
    border: "2px solid",
    borderRadius: "5px",
    padding: "8px",
  },
  alert_override: {
    "&": {
      borderLeftWidth: "4px",
      borderTop: "none",
      borderBottom: "none",
      borderRight: "none",
      boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.1)",
      width: "100%",
      backgroundColor: "white",
    },
    "& .MuiAlert-message": {
      color: "#5B4C4B",
    },
  },
  customBadge: {
    backgroundColor: "#dc8a07",
    color: "white",
    width: "80px",
    fontWeight: "700",
  },
  outOfStockCustomBadge: {
    backgroundColor: "rgba(0, 0, 0, 0.54)",
    color: "white",
    width: "105px",
    fontWeight: "700",
  },
  toolbar: {
    border: "1px solid",
    borderColor: process.env.REACT_APP_AGIZA_APP === "true" ? grey[500] : amber[700],
  },
  group_btns: {
    padding: "10px",
  },
  group_btn: {
    borderRadius: "20px",
    margin: 5,
    fontSize: "16px",
    fontWeight: "bold",
    textTransform: "unset",
  },
  group_buttons: {
    "MuiFab-sizeSmall": {
      height: "unset",
    },
  },
  primary_button: {
    marginTop: "10px",
    background: process.env.REACT_APP_PRIMARY_COLOR,
    color: "white",
  },
  button_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: "0",
    paddingRight: "0",
  },
//   outlined_button: {
//     marginTop: "10px",
//     color: process.env.REACT_APP_PRIMARY_COLOR,
//     border: "none",
//     paddingLeft: "0",
//     marginLeft: "4px",
//   },
  outlined_button: {
    marginTop: "10px",
    color: process.env.REACT_APP_PRIMARY_COLOR,
    borderColor: '#64003226',
    // width: "60%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: "18px",
  },
  iconButton: {
    padding: 10,
    color: "white",
  },
  cat_extended_btn: {
    width: "110px",
    height: "30px",
  },
  btn_bottom: {
    border: "0px",
    borderColor: primary_main_color,
  },
  stickToBottom: {
    top: "auto",
    bottom: 0,
  },
  out_of_stock_btn: {
    height: "34px",
    width: "118px",
    minWidth: "118px",
    fontSize: "12px",
    borderRadius: "17px",
  },
  product_image: {
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  list_primary_text: {
    width: "180px",
  },
  list_primary_text_for_out_of_stock: {
    width: "180px",
    color: "rgba(0, 0, 0, 0.54)",
  },
  no_decoration: {
    textDecoration: "none",
  },
  snackbar: {
    textAlign: "center",
    marginTop: "50px",
  },
});

function LoanTCs(props) {
  const { classes } = props;
  let amount = 0;
  const history = useHistory();
  const dispatch = useDispatch();
  const [open_snackbar, openSnackbar] = React.useState(false);
  const [snackbar_severity, setSnackbarSeverity] = React.useState("warning");
  const [snackbar_message, setSnackbarMessage] = React.useState(null);

  if (appStoreGet("invoice_amount") !== null) {
    amount = appStoreGet("invoice_amount");
  }

  const order = JSON.parse(appStoreGet('invoice_order'))

  const [loading, setLoading] = useState(false);
  const [loan_details, setLoanDetails] = useState(null);
  const [loans, setLoans] = useState([]);

  const retailer_data = useSelector((state) => state.auth.retailer_data);
  const corporate_settings = retailer_data.corporate_settings;

  useEffect(() => {
    let token = Cookies.get("AbsaAgiza");

    if (!token) {
      dispatch(session_expired());
      history.push('/login');
    }

    setLoading(true);
    fetch(process.env.REACT_APP_SERVER_API_URL + "/loans/loan_details", {
      method: "POST",
      headers: { ...authorization_headers(), ...{ Authorization: token } },
      body: encrypt(JSON.stringify({ loan_amount: amount })),
    })
      .then((res) => res.text())
      .then((res) => {
        let data = JSON.parse(decrypt(res));
        setLoading(false);
        setLoanDetails(data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [amount, dispatch]);

  useEffect(() => {
    let token = Cookies.get("AbsaAgiza");

    if (!token) {
      dispatch(session_expired());
      return;
    }

    if (corporate_settings && corporate_settings.allow_multiple_loans === true) {
      setLoading(true);
      fetch(process.env.REACT_APP_SERVER_API_URL + "/loans/loans_awaiting_repayment", {
        headers: { ...authorization_headers(), ...{ Authorization: token } },
      })
        .then((res) => res.text())
        .then((res) => {
          let data = JSON.parse(decrypt(res));
          setLoans(data);
          setLoading(false);
        })
        .catch(() => {
        });
    }
  }, []);

  const loans_balance = (loans) => {
    let sum = loans.reduce(function(prev, current) {
      return prev + +current.balance
    }, 0);
    return sum;
  };

  const total_loans_balance = loans_balance(loans);

  const currentFinanceLimit = () => {
    let limit = retailer_data.finance_limit - total_loans_balance
    return limit <= 0 ? 0 : limit
  }

  const toCurrency = (number) => {
    const formatter = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "KSH",
    });

    return formatter.format(number);
  };

  const sliderPercentage = () => {
    let limit = corporate_settings && corporate_settings.allow_multiple_loans === true ? parseInt(currentFinanceLimit()): parseInt(retailer_data.finance_limit);
    let total = amount;

    return (total * 100) / limit;
  };

  const makePayment = () => {
    let token = Cookies.get("AbsaAgiza");

    if (!token) {
      dispatch(session_expired());
      return;
    }

      if (retailer_data.finance_limit < amount) {
        alert("The total exceeds your limit");
        return;
      }

    let order_data = JSON.stringify({
      order_id: order.id,
      mode_of_payment: "finance",
    });

    setLoading(true);
    fetch(process.env.REACT_APP_SERVER_API_URL + "/orders/checkout", {
      method: "POST",
      headers: { ...authorization_headers(), ...{ Authorization: token } },
      body: encrypt(order_data),
    })
      .then((res) => {
        res
          .text()
          .then((text) => {
            if (res.ok) {
              let data = JSON.parse(decrypt(text));
              setLoading(false);
              appStoreSet("created_order", JSON.stringify(data));
              appStoreSet("mode_of_payment", "finance");
             
                history.push("/loan_status");
            } else {
              setSnackbarMessage(
                "Error confirming order. Please retry or contact support."
              );
              openSnackbar(true);
            }
            setLoading(false);
          })
          .catch(() => {
            setSnackbarMessage(
              "Error confirming order. Please retry or contact support."
            );
            openSnackbar(true);
            setLoading(false);
          });
      })
      .catch(() => {
        setSnackbarMessage(
          "Error confirming order. Please retry or contact support."
        );
        openSnackbar(true);
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={appTheme}>
        <Paper
          className={classes.container}
          style={{
            height: "100vh",
          }}
        >
          <HeaderBar>
            <Toolbar>
              <Link to="/" className={classes.no_decoration}>
                <IconButton
                  className={classes.iconButton}
                  aria-label="directions"
                  color="primary"
                  disableRipple
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Link>
              <Typography
                style={{ color: "white" }}
                className={classes.input}
                align="center"
              >
                { retailer_data.is_la_riba ? 'Facility terms and conditions' : 'Loan terms and conditions'}
              </Typography>
              <Link to="/" className={classes.no_decoration}>
                <Avatar
                  src={process.env.REACT_APP_TRANSPARENT_LOGO}
                  variant="square"
                  className={classes.square}
                />
              </Link>
            </Toolbar>
          </HeaderBar>

          <br />

          <Container fullWidth>
            <Typography
              align="center"
              className={classes.dialog_header}
              gutterBottom
              variant="h4"
            >
              Order now, pay later!
            </Typography>
            <Typography align="center" gutterBottom>
              { retailer_data.is_la_riba === true ? 'Your facility limit is' : 'Your loan limit is'}
              <Typography variant="h5">
                <b>{corporate_settings && corporate_settings.allow_multiple_loans === true ? toCurrency(currentFinanceLimit()): toCurrency(retailer_data.finance_limit)}</b>
              </Typography>
            </Typography>
            <FinanceLimitSlider
              defaultValue={sliderPercentage()}
              aria-labelledby="discrete-slider-restrict"
              step={null}
              className={classes.dialog_title}
              marks={[{ value: sliderPercentage() }]}
            />

            {loan_details && (
              retailer_data.is_la_riba === true ? (
                <Typography variant="h6" style={{textAlign: 'center'}}>
                  <br />
                  This Order Finance Facility of <b>{toCurrency(amount)}</b> will
                  attract a profit rate of{" "}
                  <b>
                    {loan_details.rate}% (
                    {toCurrency(loan_details.interest_amount)})
                  </b>{" "}
                  and a fee of <b>{toCurrency(loan_details.total_fees)}</b> and
                  must be repaid in <b>7 days</b>. Your total balance of{" "}
                  <b>{toCurrency(loan_details.total_amount)}</b> will be Due on{" "}
                  <b>{Moment().add(7, "d").format("DD/MM/YYYY")}</b>. Repay on
                  time to gain a higher facility limit & grow your business.
                </Typography>
              ) : (
                <Typography variant="h6" style={{textAlign: 'center'}}>
                  <br />
                  This Order Finance Loan of <b>{toCurrency(amount)}</b> will
                  attract a daily interest of{" "}
                  <b>
                    {loan_details.rate}% (
                    {toCurrency(loan_details.interest_amount)})
                  </b>{" "}
                  and a fee of <b>{toCurrency(loan_details.total_fees)}</b> and
                  must be repaid in <b>7 days</b>. Your total balance of{" "}
                  <b>{toCurrency(loan_details.total_amount)}</b> will be Due on{" "}
                  <b>{Moment().add(7, "d").format("DD/MM/YYYY")}</b>. Repay on
                  time to gain a higher loan limit & grow your business.
                </Typography>
              )
            )}

            <Container className={classes.button_container}>
              <Button
                variant="outlined"
                align="center"
                size="large"
                fullWidth={true}
                type="submit"
                className={classes.outlined_button}
                style={{ marginRight: "10px" }}
                  onClick={() => history.push("/")}
              >
                <b>Cancel</b>
              </Button>
              <Button
                variant="contained"
                align="center"
                size="large"
                fullWidth={true}
                type="submit"
                className={classes.primary_button}
                onClick={makePayment}
                
              >
                <b>Accept</b>
              </Button>
            </Container>
          </Container>

          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Paper>
      </ThemeProvider>
    </Fragment>
  );
}
export default withStyles(styles)(LoanTCs);
