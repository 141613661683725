import runtimeEnv from "@mars/heroku-js-runtime-env";
import { Paper } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {
    ThemeProvider,
    createMuiTheme,
    withStyles,
} from "@material-ui/core/styles";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import CommonStyles from "../common/commonStyles";


const bg_image_path = "/assets/images/bg_image.jpg";
const logo_path = process.env.REACT_APP_LOGO || "/assets/images/logo.png";
const organization_name = process.env.REACT_APP_ORGANISATION_NAME || "Wezesha Stock";
const primary_main_color = process.env.REACT_APP_PRIMARY_COLOR || "#640032";
const primary_links_color = process.env.REACT_APP_PRIMARY_COLOR || "#F0325A";

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_PRIMARY_COLOR,
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro"].join(","),
  },
});

const styles = (theme) => ({
  ...CommonStyles(theme),
  welcome_message: {
    color: "white",
    fontSize: "32px",
  },
  welcome_message_sub: {
    color: "white",
    fontSize: "20px",
  },
  logo: {
    height: "100px",
    width: "100px",
    backgroundRepeat: "no-repeat",
    marginTop: "200px",
    backgroundImage: `url(${process.env.REACT_APP_LOGO})`,
    backgroundColor: "white",
  },
  page_container: {
    marginTop: "20px",
    paddingTop: "40px",
    backgroundColor: "white",
    borderRadius: "8px",
  },
  iconButton: {
    padding: 10,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: "white",
  },
  primary_links: {
    color: process.env.REACT_APP_PRIMARY_COLOR,
    textDecoration: "none",
  },
  no_decoration: {
    textDecoration: "none",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
});

function ForgotSecurityQuestionsInitiate(props) {
  const { classes } = props;
  const signed = useSelector((state) => state.auth.signed);

  if (signed) {
    return <Redirect to="/" />;
  }

  const footer_message = () => {
    if (organization_name == "Wezesha Stock") {
      return "Absa Bank Kenya PLC is regulated by the Central Bank of Kenya.";
    } else {
      return "";
    }
  };

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={appTheme}>
        <Paper
          className={classes.container}
          style={{
            background: `url(${process.env.REACT_APP_BG_IMAGE})`,
            backgroundSize: "cover",
            height: "100vh",
          }}
        >
          <Grid
            position="fixed"
            style={{ boxShadow: "none", backgroundColor: "white" }}
          >
            <Toolbar>
              <Avatar
                src={process.env.REACT_APP_LOGO}
                variant="square"
                className={classes.square}
              />
            </Toolbar>
          </Grid>
          <Grid container justify="center">
            <Grid
              container
              style={{ width: "95%", maxWidth: "400px", marginTop: "90px" }}
            >
              <Grid container className={classes.root}>
                <Grid xs={12}>
                  <Grid container justify="center">
                    <Grid />
                    <Grid>
                      <Typography
                        variant="h5"
                        className={classes.welcome_message}
                      >
                        Welcome to {process.env.REACT_APP_ORGANISATION_NAME}
                      </Typography>
                      <Typography
                        variant="h5"
                        className={classes.welcome_message_sub}
                      >
                        Order stock anytime. Get instant financing.
                      </Typography>
                    </Grid>
                    <Grid />
                  </Grid>
                </Grid>
              </Grid>

              <Container className={classes.page_container} fullWidth>
                <Typography variant="body1" align="center">
                  Please contact customer care on <b>0712345678</b> to reset
                  your security questions and reset your PIN.
                </Typography>
                <br />
                <br />
                <Grid container justify="center">
                  <Typography variant="body1">
                    <Link to="/login" className={classes.primary_links}>
                      Login
                    </Link>
                  </Typography>
                </Grid>
                <br />
                <Grid container justify="center">
                  <Typography variant="body1">
                    <Link
                      to="/register_initiate"
                      className={classes.primary_links}
                    >
                      Register
                    </Link>
                  </Typography>
                </Grid>
                <br />
                <Grid container justify="center">
                  <Typography variant="caption">{footer_message()}</Typography>
                </Grid>
                <Grid container justify="center">
                  <Typography variant="caption">
                    Powered by{" "}
                    <a
                      href="https://agiza.io/"
                      target="_blank"
                      rel="noreferrer"
                      className={classes.primary_links}
                    >
                      Agiza.io
                    </a>
                  </Typography>
                </Grid>
                <br />
              </Container>
            </Grid>
          </Grid>
        </Paper>
      </ThemeProvider>
    </Fragment>
  );
}

export default withStyles(styles)(ForgotSecurityQuestionsInitiate);
