const runtimeEnv = require('@mars/heroku-js-runtime-env')

const crypto = require('crypto');
const hmacSHA512 = require('crypto-js/hmac-sha512')
const base64 = require('crypto-js/enc-base64')
const secret_key = process.env.REACT_APP_API_KEY // 32 Characters


function authorization_headers() {
    let time_stamp = Math.round(Date.now() / 1000).toString()
    let nonce = crypto.randomBytes(16).toString('base64')
    let signature_str = time_stamp + "&" + nonce
    let signature_hash = hmacSHA512(signature_str, secret_key)
    let signature = base64.stringify(signature_hash)

    return {
        Timestamp: time_stamp,
        Nonce: nonce,
        Signature: signature,
        "Content-Type": "text/html",
    }
}

module.exports = {
    authorization_headers
}
