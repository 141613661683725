import runtimeEnv from "@mars/heroku-js-runtime-env";
import { Paper } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputBase from "@material-ui/core/InputBase";
import Snackbar from "@material-ui/core/Snackbar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {
    ThemeProvider,
    createMuiTheme,
    withStyles,
} from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import React, { Fragment, useState } from "react";
import * as Icon from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CommonStyles from "../common/commonStyles";
import { update_retailer } from "../store/modules/auth/actions";
import { authorization_headers } from "../utils/api_authorization";
import { encrypt } from "../utils/crypto_encryption";


const bg_image_path = "/assets/images/bg_image.jpg";
const logo_path = process.env.REACT_APP_LOGO || "/assets/images/logo.png";
const organization_name = process.env.REACT_APP_ORGANISATION_NAME || "Wezesha Stock";
const primary_main_color = process.env.REACT_APP_PRIMARY_COLOR || "#640032";

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_PRIMARY_COLOR,
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro"].join(","),
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="outlined" {...props} />;
}

const styles = (theme) => ({
  ...CommonStyles(theme),
  alert_override: {
    "&": {
      borderLeftWidth: "4px",
      borderTop: "none",
      borderBottom: "none",
      borderRight: "none",
      boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.1)",
      width: "100%",
      backgroundColor: "white",
    },
    "& .MuiAlert-message": {
      color: "#5B4C4B",
    },
  },
  welcome_message: {
    color: "white",
    fontSize: "32px",
  },
  welcome_message_sub: {
    color: "white",
    fontSize: "20px",
  },
  logo: {
    height: "100px",
    width: "100px",
    backgroundRepeat: "no-repeat",
    marginTop: "200px",
    backgroundImage: `url(${process.env.REACT_APP_LOGO})`,
  },
  page_container: {
    marginTop: "20px",
    paddingTop: "40px",
    backgroundColor: "white",
    borderRadius: "8px",
  },
  fields_toolbar: {
    borderColor: "#C9C9C9",
    border: "1px solid",
    borderRadius: "4px",
    paddingLeft: "8px",
  },
  iconButton: {
    padding: 10,
  },
  input_label: {
    marginBottom: "2px",
  },
  primary_button: {
    background: process.env.REACT_APP_PRIMARY_COLOR,
    color: "white",
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    "& .MuiInputBase-input::placeholder": {
      fontStyle: "italic",
    },
  },
  disclaimer_msg: {
    paddingTop: "10px",
    textAlign: "center",
    fontSize: "0.75rem",
  },
  disclaimer_checkbox_msg: {
    paddingTop: "5px",
    textAlign: "center",
  },
  disclaimer_checkbox: {
    marginTop: "-5px",
  },
  no_decoration: {
    textDecoration: "none",
    color: "inherit !important",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  snackbar: {
    textAlign: "center",
    marginTop: "50px",
  },
});

function SecurityQuestions(props) {
  const { classes } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState(new Map());

  const [loading, setLoading] = React.useState(false);
  const [open_snackbar, openSnackbar] = React.useState(false);
  const [snackbar_severity, setSnackbarSeverity] = React.useState("warning");
  const [snackbar_message, setSnackbarMessage] = React.useState(null);

  const retailer_id = useSelector(
    (state) => state.auth.retailer_data
  ).retailer_id;
  const security_question1 = useSelector(
    (state) => state.auth.retailer_data
  ).security_question1;
  const security_question2 = useSelector(
    (state) => state.auth.retailer_data
  ).security_question2;

  const upsert = (key, value) => {
    setState((prev) => new Map(prev).set(key, value));
  };

  const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    upsert(name, value);
  };

  const isNumeric = (str) => {
    if (typeof str != "string") return false; // only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  };

  const isValidated = () => {
    if (!state.get("security_question1_answer")) {
      setSnackbarMessage(
        "Answer for '" + security_question1 + "' cannot be blank."
      );
      openSnackbar(true);
      return false;
    } else if (!state.get("security_question2_answer")) {
      setSnackbarMessage(
        "Answer for '" + security_question2 + "' cannot be blank."
      );
      openSnackbar(true);
      return false;
    } else if (!state.get("pin")) {
      setSnackbarMessage("Pin cannot be blank.");
      openSnackbar(true);
      return false;
    } else if (!isNumeric(state.get("pin"))) {
      setSnackbarMessage("Pin should be a 4 digit number.");
      openSnackbar(true);
      return false;
    } else if (state.get("pin").length !== 4) {
      setSnackbarMessage("Pin should be a 4 digit number.");
      openSnackbar(true);
      return false;
    } else if (!state.get("confirm_pin")) {
      setSnackbarMessage("Confirm pin cannot be blank.");
      openSnackbar(true);
      return false;
    } else if (state.get("pin") !== state.get("confirm_pin")) {
      setSnackbarMessage("Pin do not match");
      openSnackbar(true);
      return false;
    }

    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!isValidated()) {
      return;
    }

    let params = {
      retailer_id: retailer_id,
      security_question1_answer: state.get("security_question1_answer"),
      security_question2_answer: state.get("security_question2_answer"),
      pin: state.get("pin"),
      confirm_pin: state.get("confirm_pin"),
    };

    setLoading(true);
    fetch(
      process.env.REACT_APP_SERVER_API_URL + "/retailers/reset_pin_security_questions",
      {
        method: "POST",
        headers: authorization_headers(),
        body: encrypt(JSON.stringify(params)),
      }
    )
      .then((res) => {
        res
          .text()
          .then((text) => {
            if (res.ok) {
              setLoading(false);
              setSnackbarMessage(
                "PIN reset successful. You'll be redirected to login page shortly."
              );
              setSnackbarSeverity("success");
              openSnackbar(true);
              dispatch(update_retailer({}));

              sleep(5000).then(() => {
                history.push("/");
              });
            } else if (res.status === 403) {
              setSnackbarMessage("Invalid answers for the security questions.");
              openSnackbar(true);
            } else if (res.status === 422) {
              setSnackbarMessage(
                "Invalid data. Please start the PIN reset process again."
              );
              openSnackbar(true);
            } else {
              setSnackbarMessage(
                "Error submitting information. Please retry or contact support."
              );
              openSnackbar(true);
            }

            setLoading(false);
          })
          .catch(() => {
            setSnackbarMessage(
              "Error submitting information. Please retry or contact support."
            );
            openSnackbar(true);
            setLoading(false);
          });
      })
      .catch(() => {
        setSnackbarMessage(
          "Error submitting information. Please retry or contact support."
        );
        openSnackbar(true);
        setLoading(false);
      });
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    openSnackbar(false);
    setSnackbarSeverity("warning");
  };

  const snackbarLoader = () => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className={classes.snackbar}
        open={open_snackbar}
      >
        <Alert
          onClose={() => closeSnackbar()}
          severity={snackbar_severity}
          className={classes.alert_override}
        >
          {snackbar_message}
        </Alert>
      </Snackbar>
    );
  };

  const progressLoader = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const footer_message = () => {
    if (organization_name == "Wezesha Stock") {
      return "Absa Bank Kenya PLC is regulated by the Central Bank of Kenya.";
    } else {
      return "";
    }
  };

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={appTheme}>
        <Paper
          className={classes.container}
          style={{
            background: `url(${process.env.REACT_APP_BG_IMAGE})`,
            backgroundSize: "cover",
            height: "100vh",
          }}
        >
          <Grid
            position="fixed"
            style={{ boxShadow: "none", backgroundColor: "white" }}
          >
            <Toolbar>
              <Avatar
                src={process.env.REACT_APP_LOGO}
                variant="square"
                className={classes.square}
              />
            </Toolbar>
          </Grid>
          <Grid container justify="center">
            <Grid
              container
              style={{ width: "95%", maxWidth: "400px", marginTop: "90px" }}
            >
              <Grid container className={classes.root}>
                <Grid xs={12}>
                  <Grid container justify="center">
                    <Grid />
                    <Grid>
                      <Typography
                        variant="h5"
                        className={classes.welcome_message}
                      >
                        Welcome to {process.env.REACT_APP_ORGANISATION_NAME}
                      </Typography>
                      <Typography
                        variant="h5"
                        className={classes.welcome_message_sub}
                      >
                        Order stock anytime. Get instant financing.
                      </Typography>
                    </Grid>
                    <Grid />
                  </Grid>
                </Grid>
              </Grid>

              <Container className={classes.page_container} fullWidth>
                <form onSubmit={handleSubmit}>
                  <Typography variant="body1" className={classes.input_label}>
                    {security_question1}*
                  </Typography>
                  <Toolbar className={classes.fields_toolbar}>
                    <InputAdornment position="start">
                      <Icon.Shield style={{ color: "#a19f9f" }} />
                    </InputAdornment>
                    <Divider orientation="vertical" flexItem />
                    <InputBase
                      className={classes.input}
                      placeholder="Enter answer"
                      name="security_question1_answer"
                      value={state.get("answer_for_security_question1")}
                      onChange={handleChange}
                    />
                  </Toolbar>
                  <br />
                  <Typography variant="body1" className={classes.input_label}>
                    {security_question2}*
                  </Typography>
                  <Toolbar className={classes.fields_toolbar}>
                    <InputAdornment position="start">
                      <Icon.Shield style={{ color: "#a19f9f" }} />
                    </InputAdornment>
                    <Divider orientation="vertical" flexItem />
                    <InputBase
                      className={classes.input}
                      placeholder="Enter answer"
                      name="security_question2_answer"
                      value={state.get("answer_for_security_question2")}
                      onChange={handleChange}
                    />
                  </Toolbar>
                  <br />
                  <Typography variant="body1" className={classes.input_label}>
                    New PIN
                  </Typography>
                  <Toolbar className={classes.fields_toolbar}>
                    <InputAdornment position="start">
                      <Icon.Lock style={{ color: "#a19f9f" }} />
                    </InputAdornment>
                    <Divider orientation="vertical" flexItem />
                    <InputBase
                      className={classes.input}
                      placeholder="Enter PIN"
                      name="pin"
                      type="password"
                      value={state.get("pin")}
                      onChange={handleChange}
                    />
                  </Toolbar>
                  <br />
                  <Typography variant="body1" className={classes.input_label}>
                    Confirm new PIN
                  </Typography>
                  <Toolbar className={classes.fields_toolbar}>
                    <InputAdornment position="start">
                      <Icon.Lock style={{ color: "#a19f9f" }} />
                    </InputAdornment>
                    <Divider orientation="vertical" flexItem />
                    <InputBase
                      className={classes.input}
                      placeholder="Confirm PIN"
                      name="confirm_pin"
                      type="password"
                      value={state.get("confirm_pin")}
                      onChange={handleChange}
                    />
                  </Toolbar>
                  <br />
                  <Grid container justify="center">
                    <Button
                      variant="contained"
                      align="center"
                      size="large"
                      fullWidth={true}
                      color="primary"
                      type="submit"
                      className={classes.primary_button}
                    >
                      <b>Submit</b>
                    </Button>
                  </Grid>
                  <br />
                  <Grid container justify="center">
                    <Typography variant="caption">
                      {footer_message()}
                    </Typography>
                  </Grid>
                  <Grid container justify="center">
                    <Typography variant="caption">
                      Powered by{" "}
                      <a
                        href="https://agiza.io/"
                        target="_blank"
                        rel="noreferrer"
                        className={classes.primary_links}
                      >
                        Agiza.io
                      </a>
                    </Typography>
                  </Grid>
                  <br />
                </form>
              </Container>

              {snackbarLoader()}
              {progressLoader()}
            </Grid>
          </Grid>
        </Paper>
      </ThemeProvider>
    </Fragment>
  );
}

export default withStyles(styles)(SecurityQuestions);
