const runtimeEnv = require('@mars/heroku-js-runtime-env')

const crypto = require('crypto')
const algorithm = 'aes-256-cbc'
const key = process.env.REACT_APP_ENCRYPTION_KEY // 32 Characters
const iv = process.env.REACT_APP_ENCRYPTION_IV // 16 Characters

function encrypt(text) {
    let cipher = crypto.createCipheriv(algorithm, key, iv)
    let crypted = cipher.update(text, 'utf-8', "base64")
    crypted += cipher.final("base64")
    return crypted
}

function decrypt(text) {
    let cipher = crypto.createDecipheriv(algorithm, key, iv)
    let crypted = cipher.update(text, "base64", "utf-8")
    crypted += cipher.final("utf-8")
    return crypted
}

module.exports = {
    encrypt, decrypt
}
