import runtimeEnv from "@mars/heroku-js-runtime-env";
import { Grid, Paper } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Snackbar from "@material-ui/core/Snackbar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ClearIcon from '@material-ui/icons/Clear';
import { amber, grey } from "@material-ui/core/colors";
import {
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import MuiAlert from "@material-ui/lab/Alert";
import * as Cookies from "js-cookie";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import CommonStyles from "../common/commonStyles";
import HeaderBar from "../common/header_bar";
import { session_expired } from "../store/modules/auth/actions";
import { authorization_headers } from "../utils/api_authorization";
import { appStoreGet, appStoreSet } from "../utils/app_storage";
import { decrypt, encrypt } from "../utils/crypto_encryption";
import imageCompression from 'browser-image-compression';


const transparent_logo_path =
  process.env.REACT_APP_TRANSPARENT_LOGO_PATH || "/assets/images/logo_transparent.png";
const primary_main_color = process.env.REACT_APP_PRIMARY_COLOR || "#640032";

const sleep = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_PRIMARY_COLOR,
    },
    secondary: {
      main: "#882456",
    },
    white: {
      main: grey[50],
      body1: {
        fontSize: "18px",
      },
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro"].join(","),
    body1: {
      fontSize: "18px",
    },
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="outlined" {...props} />;
}

const styles = (theme) => ({
  ...CommonStyles(theme),
  fields_toolbar: {
    borderColor: "#737373",
    border: "2px solid",
    borderRadius: "5px",
    paddingLeft: "8px",
  },
  upload_container: {
    marginTop: "10px",
  },
  upload_fields_toolbar: {
    borderColor: "#737373",
    border: "2px solid",
    borderRadius: "5px",
    padding: "8px",
  },
  alert_override: {
    "&": {
      borderLeftWidth: "4px",
      borderTop: "none",
      borderBottom: "none",
      borderRight: "none",
      boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.1)",
      width: "100%",
      backgroundColor: "white",
    },
    "& .MuiAlert-message": {
      color: "#5B4C4B",
    },
  },
  customBadge: {
    backgroundColor: "#dc8a07",
    color: "white",
    width: "80px",
    fontWeight: "700",
  },
  outOfStockCustomBadge: {
    backgroundColor: "rgba(0, 0, 0, 0.54)",
    color: "white",
    width: "105px",
    fontWeight: "700",
  },
  toolbar: {
    border: "1px solid",
    borderColor: process.env.REACT_APP_AGIZA_APP === "true" ? grey[500] : amber[700],
  },
  group_btns: {
    padding: "10px",
  },
  group_btn: {
    borderRadius: "20px",
    margin: 5,
    fontSize: "16px",
    fontWeight: "bold",
    textTransform: "unset",
  },
  group_buttons: {
    "MuiFab-sizeSmall": {
      height: "unset",
    },
  },
  primary_button: {
    marginTop: '10px',
    background: process.env.REACT_APP_PRIMARY_COLOR,
    color: 'white',
  },
  outlined_button: {
      marginTop: '10px',
      color: process.env.REACT_APP_PRIMARY_COLOR,
      border: 'none',
      paddingLeft: '0',
      marginLeft: '4px'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: "18px",
  },
  end_button: {
    fontWeight: "bold",
    color: "red"
  },
  iconButton: {
    padding: 10,
    color: "white",
  },
  cat_extended_btn: {
    width: "110px",
    height: "30px",
  },
  btn_bottom: {
    border: "0px",
    borderColor: primary_main_color,
  },
  stickToBottom: {
    top: "auto",
    bottom: 0,
  },
  out_of_stock_btn: {
    height: "34px",
    width: "118px",
    minWidth: "118px",
    fontSize: "12px",
    borderRadius: "17px",
  },
  product_image: {
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  list_primary_text: {
    width: "180px",
  },
  list_primary_text_for_out_of_stock: {
    width: "180px",
    color: "rgba(0, 0, 0, 0.54)",
  },
  no_decoration: {
    textDecoration: "none",
  },
  snackbar: {
    textAlign: "center",
    marginTop: "50px",
  },
});

function Invoicing(props) {
  const { classes } = props;
  let amount = 0
  const history = useHistory();
  const dispatch = useDispatch();

  if (appStoreGet("invoice_order_amount") !== null) {
    amount = appStoreGet("invoice_order_amount");
  }

  const [loading, setLoading] = useState(false);
  const [open_snackbar, setOpenSnackbar] = useState(false);
  const [snackbar_message, setSnackbarMessage] = useState(null);
  const [snackbar_severity, setSnackbarSeverity] = useState(null);
  const [invoice_amount, setInvoiceAmount] = useState(amount);
  const [file, setFile] = useState({});
  const [error, setError] = useState(null);
  const [loans, setLoans] = useState([]);

  const retailer_data = useSelector((state) => state.auth.retailer_data);
  const retailer = retailer_data;
  const corporate_settings = retailer.corporate_settings

  let enable_submit_button = file.name && invoice_amount && invoice_amount > 0


  useEffect(() => {
    let token = Cookies.get("AbsaAgiza");

    if (!token) {
      dispatch(session_expired());
      history.push('/login')
    }

    if (corporate_settings && corporate_settings.allow_multiple_loans === true) {
      setLoading(true);
      fetch(process.env.REACT_APP_SERVER_API_URL + "/loans/loans_awaiting_repayment", {
        headers: { ...authorization_headers(), ...{ Authorization: token } },
      })
        .then((res) => res.text())
        .then((res) => {
          let data = JSON.parse(decrypt(res));
          setLoans(data);
          setLoading(false);
        })
        .catch(() => {
        });
    }
  }, []);


  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    setSnackbarSeverity("warning");
  };

  const financeLimit = () => {
    let limit = retailer.finance_limit;

    if (!limit) {
      return 0;
    }
    return limit;
  };

  const loans_balance = (loans) => {
    let sum = loans.reduce(function(prev, current) {
      return prev + +current.balance
    }, 0);
    return sum;
  };

  const total_loans_balance = loans_balance(loans);

  const currentFinanceLimit = () => {
    let limit = financeLimit() - total_loans_balance
    return limit <= 0 ? 0 : limit
  }

  const bytesToMegabytes = (bytes) => {
    return (bytes / (1024 * 1024)).toFixed(2);
  }


  const getBase64 = (upload_file) => {
    return new Promise(resolve => {
        let fileInfo;
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(upload_file);

        // on reader load somthing...
        reader.onload = () => {
            // Make a fileInfo Object
            baseURL = reader.result;
            resolve(
              {
                name: upload_file.name,
                url: baseURL,
                type: upload_file.type,
                size: bytesToMegabytes(upload_file.size)
              });
        };
        console.log(fileInfo);
    });
  };

  const handleChange = async (event) => {
      if (event.target.name === 'upload_file') {
          let upload_file = event.target.files[0];

          // Check if the file type is an image (e.g., JPEG, PNG, etc.)
          if (upload_file.type.startsWith('image/')) {
            const options = {
              maxSizeMB: 0.5,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };

            try {
              const compressedFile = await imageCompression(upload_file, options);
              upload_file = compressedFile
              console.log('Compressed image:', compressedFile);
              // Handle the compressed file as needed (e.g., upload to server)
            } catch (error) {
              console.error('Error compressing image:', error);
              setSnackbarMessage(
                "Something happend while uploading the file. Please try again"
              );
              setOpenSnackbar(true);
            }
          } else {
            // Handle non-image files (e.g., PDFs, documents, etc.)
            console.log('Skipping compression for non-image file:', upload_file.name);
          }

          getBase64(upload_file)
              .then(result => {
                setFile(result)
              })
              .catch(err => {
                console.error(err);
                setSnackbarMessage(
                  "Something happend while uploading the file. Please try again"
                );
                setOpenSnackbar(true);
              });
      } else {
        setSnackbarMessage(
          "Something happend while uploading the file. Please try again"
        );
        setOpenSnackbar(true);
      }
  }

  const clearFile = (event) => {
    event.preventDefault();
    setFile({});
  };

  const submitOrder = () => {
    const data = {
      invoice_amount: invoice_amount,
      file: file,
    };

    console.log("Data submitted")
    console.log(data);

    let token = Cookies.get("AbsaAgiza");

    if (!token) {
      dispatch(session_expired());
      return;
    }

    setLoading(true);
    fetch(process.env.REACT_APP_SERVER_API_URL + "/orders/create_invoice_order", {
      method: "POST",
      headers: { ...authorization_headers(), ...{ Authorization: token } },
      body: encrypt(JSON.stringify(data)),
    })
      .then((res) => res.text())
      .then((res) => {
        let data = JSON.parse(decrypt(res));
        setLoading(false);
        // setSnackbarMessage("Order successfully submitted for approval.");
        // setSnackbarSeverity("success");
        // setOpenSnackbar(true);
        // appStoreRemove("invoice_order_amount")
        if (data && data.error) {
          setSnackbarMessage(
            data.error
          );
          setOpenSnackbar(true);
          return
        } else {
          appStoreSet("invoice_amount", invoice_amount)
          appStoreSet("invoice_order", JSON.stringify(data))

          // sleep(2000).then(() => {
            // history.push("/pending_orders");
            history.push("/loan_terms");
          // });
        }
      })
      .catch((error) => {
        console.log("Error " + error)
        setSnackbarMessage(
          "Something went wrong while submitting the invoice order. Please contact support"
        );
        setOpenSnackbar(true);
        setLoading(false);
      });
  };

  const toCurrency = (number) => {
    const formatter = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "KSH",
    });

    return formatter.format(number);
  };

  const setAmount = (event) => {
    let limit = corporate_settings && corporate_settings.allow_multiple_loans === true ? currentFinanceLimit() : financeLimit();
    setInvoiceAmount(event.target.value);
    if (event.target.value > limit) {
        setError('Amount exceeds your finance limit');
    } else {
        setError(null);
    }
}

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={appTheme}>
      <Paper
          className={classes.container}
          style={{
            height: "100vh",
          }}
        >
        <HeaderBar>
          <Toolbar>
            <Link to="/" className={classes.no_decoration}>
              <IconButton
                className={classes.iconButton}
                aria-label="directions"
                color="primary"
                disableRipple
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Link>
            <Typography style={{color: 'white'}} className={classes.input} align="center">
              Invoice Order
            </Typography>
            <Link to="/" className={classes.no_decoration}>
              <Avatar
                src={process.env.REACT_APP_TRANSPARENT_LOGO}
                variant="square"
                className={classes.square}
              />
            </Link>
          </Toolbar>
        </HeaderBar>

        <br />

        <Container fullWidth>
          <Typography variant="body1" className={classes.input_label}>
            Invoice amount
          </Typography>
          <Toolbar className={classes.fields_toolbar} style={{minHeight: '42px'}}>
            <InputBase
              className={classes.input}
              placeholder="Enter invoice amount"
              value={invoice_amount}
              onChange={setAmount}
              style={{minHeight: '42px'}}
            />
          </Toolbar>
          {error && <><Typography variant="body" style={{color: 'red'}}>
            {error}
          </Typography><br/></>}
          <Typography variant="body">
            Your Finance limit is {
              corporate_settings && corporate_settings.allow_multiple_loans === true ?
                toCurrency(currentFinanceLimit()) :
                toCurrency(financeLimit())
              }
          </Typography>
        </Container>

        <Container fullwidth style={{ marginTop: "10px" }}>
          <label htmlFor="upload-image" style={{ marginRight: "10px", marginLeft: '0', paddingLeft: '0' }}>
          <Grid>
          {file.name ? <>
            <Button
              variant="outlined"
              startIcon={<CloudUploadIcon />}
              endIcon={<ClearIcon className={classes.end_button}/>}
              className={classes.outlined_button}
              id="upload-image"
              component="span"
              size="large"
              style={{ width: "100%" }}
              onClick={(event) => clearFile(event)}
            >
              {file.name}
            </Button>

            <input
                placeholder="Upload file"
                name="upload_file"
                hidden
                type="file"
                accept="image/*,application/pdf,.pdf"
                capture="camera"
                onChange={handleChange}
            />
            </>
            :
            <>
            <Button
              variant="contained"
              size="large"
              startIcon={<CloudUploadIcon />}
              className={classes.primary_button}
              component="span"
              fullwidth={true}
              style={{ width: "100%" }}
            >
              Upload invoice
            </Button>

            <input
                placeholder="Upload file..."
                name="upload_file"
                id="upload-image"
                hidden
                type="file"
                accept="image/*,application/pdf,.pdf"
                capture="camera"
                onChange={handleChange}
            />

            </>}
            </Grid>
          </label>
        </Container>

        { enable_submit_button && (
          <Container fullWidth style={{ marginTop: "0px" }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => submitOrder()}
              fullWidth
              disabled={error != null}
            >
              Submit
            </Button>
          </Container>
        )}
        </Paper>
      </ThemeProvider>
    </Fragment>
  );
}
export default withStyles(styles)(Invoicing);
