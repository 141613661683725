import runtimeEnv from "@mars/heroku-js-runtime-env";
import AppBar from "@material-ui/core/AppBar";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Typography from "@material-ui/core/Typography";
import { grey, red } from "@material-ui/core/colors";
import { ThemeProvider, createMuiTheme, withStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import * as Cookies from "js-cookie";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CommonStyles from "../common/commonStyles";
import { session_expired } from "../store/modules/auth/actions";
import { authorization_headers } from "../utils/api_authorization";
import { appStoreGet } from "../utils/app_storage";
import { decrypt, encrypt } from "../utils/crypto_encryption";



const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_PRIMARY_COLOR || "#af144b",
    },
    white: {
      main: grey[50],
    },
    success: {
      main: red[500],
    },
  },
});

const styles = (theme) => ({
  ...CommonStyles(theme),
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  btn_continue_shopping: {
    marginBottom: "2%",
  },
  stickToBottom: {
    top: "auto",
    bottom: 0,
    left: "auto",
    right: "auto",
    position: "fixed",
  },
  list: {
    marginTop: "100px",
    marginBottom: "60px",
    borderRadius: "0px",
  },
  success_icon: {
    backgroundColor: "#00b89f",
    boxShadow: "none",
    color: grey[50],
    marginTop: "40px",
  },
  fail_icon: {
    backgroundColor: red[500],
    boxShadow: "none",
    color: grey[50],
    marginTop: "40px",
  },
  btn_success: {
    backgroundColor: "#00b89f",
    boxShadow: "none",
    color: grey[50],
    borderRadius: "20px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
});

function PaymentStatus(props) {
  const history = useHistory();
  const { classes } = props;
  const dispatch = useDispatch();

  let loan_repayments = null;
  let timer = null;
  let polling_count = 0;

  if (appStoreGet('loan_repayments') !== null)
      loan_repayments = JSON.parse(appStoreGet('loan_repayments'));

  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    timer = setInterval(() => fetchStatus(), 5000);
  }, []);

  let loan_repayment_ids = loan_repayments.map((loan_repayment) => loan_repayment.id);

  const stopInterval = () => {
    clearInterval(timer);
    setLoading(false);
    timer = null;
  };

  const fetchStatus = () => {
    let token = Cookies.get("AbsaAgiza");

    if (!token) {
      dispatch(session_expired());
      history.push('/login');
    }

    if (polling_count >= 20) stopInterval();


    fetch(process.env.REACT_APP_SERVER_API_URL + "/loan_repayments/status", {
      method: "POST",
      headers: { ...authorization_headers(), ...{ Authorization: token } },
      body: encrypt(JSON.stringify({ loan_repayment_ids: loan_repayment_ids})),
    })
      .then((res) => res.text())
      .then((res) => {
        let responses = JSON.parse(decrypt(res));
        let status_array = responses['statuses'].map(data => data.status)

        if (status_array.includes("pending")) {
          return
        } else if (status_array.includes("success")) {
          stopInterval();

          setError(responses['statuses'].map((item) => item.error))
          setStatus(responses['statuses'].map((item) => item.status));
        } else {
          setStatus(["failed"]);
          stopInterval();
        }
      })
      .catch(() => {
        setStatus(["failed"]);
        stopInterval();
      });

    polling_count++;
  };

  const keepShopping = () => {
    history.push("/");
  };

  const progressLoader = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const paymentAmount = () => {
    let sum = loan_repayments.reduce(function(prev, current) {
            return prev + +current.amount
        }, 0);
    return sum;
  };

  const toCurrency = (number) => {
    const formatter = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "KSH",
      minimumFractionDigits: 0,
    });

    return formatter.format(number);
  };

  const successfulPayment = () => {
    if (status.includes("success")) {
      return (
        <Container maxWidth="sm">
          <Grid container className={classes.root}>
            <Grid xs={12}>
              <Grid container justify="center">
                <Grid />
                <Grid>
                  <Fab
                    className={classes.success_icon}
                    align="center"
                    size="large"
                    fullWidth
                  >
                    <CheckIcon />
                  </Fab>
                </Grid>
                <Grid />
              </Grid>
            </Grid>
          </Grid>
          <Typography align="center" fullWidth>
            <b>Your payment has been successfully received</b>
          </Typography>
        </Container>
      );
    }
  };

  const retryPayment = () => {
    const server_api_url =
      process.env.REACT_APP_SERVER_API_URL + "/loan_repayments/retry_payment";
    let token = Cookies.get("AbsaAgiza");

    if (!token) {
      dispatch(session_expired());
      history.push('/login');
    }

    let loan_repayment_data = JSON.stringify({
      loan_repayment_ids: loan_repayment_ids,
    });

    fetch(server_api_url, {
      method: "POST",
      headers: { ...authorization_headers(), ...{ Authorization: token } },
      body: encrypt(loan_repayment_data),
    })
      .then((res) => {
        res
          .text()
          .then((text) => {
            // let data = JSON.parse(decrypt(text))
            window.location.reload(false);
          })
          .catch(() => setStatus(["failed"]));
      })
      .catch(() => setStatus(["failed"]));
  };

  const failedPayment = () => {
    if (status.includes("failed")) {
      return (
        <Container maxWidth="sm">
          <Grid container className={classes.root}>
            <Grid xs={12}>
              <Grid container justify="center">
                <Grid />
                <Grid>
                  <Fab
                    className={classes.fail_icon}
                    align="center"
                    size="large"
                    fullWidth
                  >
                    <ClearIcon />
                  </Fab>
                </Grid>
                <Grid />
              </Grid>
            </Grid>
          </Grid>
          <Typography align="center" fullWidth>
            <b>
              {" "}
              Sorry, we are unable to trigger instant Mpesa payment of{" "}
              {toCurrency(paymentAmount())} on your phone number - {error}.
            </b>
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            align="center"
            className={classes.btn_continue_shopping}
            onClick={() => retryPayment()}
            fullWidth
          >
            <b>RETRY PAYMENT</b>
          </Button>
          ;
        </Container>
      );
    }
  };

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={appTheme}>
        <Paper
          className={classes.container}
          style={{
            height: "100vh",
          }}
        >
          {successfulPayment()}
          {failedPayment()}
          <AppBar
            className={`${classes.stickToBottom} ${classes.contained_width}`}
            color="white"
          >
            <TableContainer component={Paper}>
              <Container maxWidth="sm">
                <Button
                  variant="contained"
                  color="primary"
                  align="center"
                  className={classes.btn_continue_shopping}
                  onClick={() => keepShopping()}
                  fullWidth
                >
                  <b>BACK HOME</b>
                </Button>
              </Container>
            </TableContainer>
          </AppBar>
          {progressLoader()}
        </Paper>
      </ThemeProvider>
    </Fragment>
  );
}

export default withStyles(styles)(PaymentStatus);
